import ReactComponent from "../../app/ReactComponent";
import dayjs from "dayjs";

export class OverallSamplesTable extends ReactComponent {
  state = {
    samples: []
  }

  constructor(props) {
    super(props);


  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  render() {
    return (

      <div className={"samples-chart-table-wrapper"}>
        <div className="samples-chart-table">
          {this.props.data.items.map((item, i, items) => (
            <div className="dib chart-table-col">
              <div className="chart-table-row row-date">
                {dayjs(item.properties.date).format("D.M.")}<br/>
                <span className="row-year">{dayjs(item.properties.date).format("YYYY")}</span>
              </div>
              <div className="chart-table-row">{item.properties.weight}</div>
              <div className="chart-table-row">{item.properties.pulse}</div>
              <div className="chart-table-row">{item.properties.blood_pressure}</div>
              <div className="chart-table-row">{item.properties.temperature}</div>
            </div>
          ))}
        </div>
        <div className="chart-table-header">
          <div className={"chart-table-header-item"}>Datum</div>
          <div className={"chart-table-header-item"}>Váha</div>
          <div className={"chart-table-header-item"}>Puls</div>
          <div className={"chart-table-header-item"}>Tlak</div>
          <div className={"chart-table-header-item"}>Teplota</div>
        </div>

      </div>
    )
  }
}