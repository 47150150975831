import dayjs from 'dayjs'

export function serializeObject (form) {
    /*
    const result = {}
    const data = $(form).serializeArray()

    $.each(data, (index, element) => {

        const node = result[element.name]

        if (typeof node !== 'undefined' && node !== null) {
            if ($.isArray(node)) {
                node.push(element.value)
            } else {
                result[element.name] = [node, element.value]
            }
        } else {
            result[element.name] = element.value
        }
    })

    return result
*/
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getScript (url, callback = () => {}) {
    let head = document.querySelector('head');
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onreadystatechange = arguments[1];
    script.onload = callback;
    head.appendChild(script);

}

export function randomString () {

    return (Math.random() + 1).toString(36).substr(2, 8)

}

export function parameterizeArray (key, arr) {

    arr = arr.map(encodeURIComponent)

    return `?${key}[]=${arr.join(`&${key}[]=`)}`

}

export function prettyDate (str, outputFormat = 'D.M.YYYY, H:mm [h]', inputFormat = 'YYYY-MM-DD HH:mm:ss') {

    const date = dayjs(str, inputFormat)

    return (date.isValid() ? date.format(outputFormat) : dayjs().format(outputFormat))

}

export function prettyNumber (str, decimals = 0) {

    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')

}

export function isEmail (str) {

    return /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(str)

}

export function isPassword (str) {

    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/gm.test(str)

}

export function createSafePassword (strength = 12) {

    const characters = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const uArray = new Uint32Array(characters.length)

    let password = '0Ab?'

    window.crypto.getRandomValues(uArray)

    for (let i = 0; i < strength; i++) {
        password += characters[uArray[i] % characters.length]
    }

    return password

}

export function extractOrigin (url) {

    try {

        const { origin } = new URL(url)

        return origin

    } catch (error) {

        return 'http://localhost:3000'

    }

}

export function hex2rgba (hex, alpha = 1) {

    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))

    return `rgba(${r},${g},${b},${alpha})`

}

export function fixZIP (str = '') {

    return str.replace(/\D/g, '').substring(0, 5)

}

export function fixNullString (str = '') {

    str = (str ? str.toString() : '')

    return (str && str.length ? str : null)

}