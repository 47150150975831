export function mapBookings (bookings) {

	return bookings.reduce((acc, {booking_id, booking_items}) => {

		const stack = []

		booking_items.forEach((booking_item) => {
			stack.push({
				...booking_item,
				booking_id
			})
		})


		return acc.concat(stack)

	}, []);

}

export function mapStores (stores) {

	return stores.filter(({state}) => !!state)

}

export function getBookingStatusNameByStatusId (booking_statuses = [], id, default_value = '') {

	const { name: booking_status_name = default_value } = (booking_statuses.find(({booking_status_id}) => booking_status_id === id) ?? {})

	return booking_status_name

}

export function getBookingTicketNameByTicketId (booking_tickets = [], id, default_value = '') {

	const { name: booking_ticket_name = default_value } = (booking_tickets.find(({ticket_id}) => ticket_id === id) ?? {})

	return booking_ticket_name

}

export function getBookingStoreNameByResourceRecordId (booking_stores = [], id, default_value = '') {

	const { name: booking_store_name = default_value } = (booking_stores.find(({resource_record_id}) => resource_record_id === id) ?? {})

	return booking_store_name

}
