import ReactComponent from '../app/ReactComponent'
import React from 'react'
import { Tooltip } from 'antd'

export class ReservationTimeAvailability extends ReactComponent {
  state = {
    average_booking_busy_times: [],
    initialized: false
  }

  constructor (props) {
    super(props)

  }

  async componentDidMount() {

    await this.getAverageBookingBusyTimes()

    await this.updateState({initialized: true})

  }

  async componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.date !== prevProps.date){
      await this.getAverageBookingBusyTimes()
    }

  }

  async getAverageBookingBusyTimes() {

    try {

      if (this.props.date !== null && this.props.store !== null){
        const busy_times = await this.$api({
          action: 'carecloud_api',
          method: 'POST',
          route: 'bookings/actions/average-booking-busy-times',
          data: {
            time_period: 'day',
            booking_date: this.props.date,
            booking_property_id: 'store',
            booking_property_value: this.props.store
          }
        })

        const expanded_busy_times = busy_times.chart_data.map((data)=>{
          return{
            ...data,
            height: data.y_axis_value < 10 ? "4px" : ((data.y_axis_value*0.46).toFixed(2) + "px"),
            tooltip: data.y_axis_value < 30 ? "Obvykle nižší vytížení" : data.y_axis_value > 30 && data.y_axis_value < 70 ? "Obvykle stálé vytížení" : "Obvykle trochu rušno"
          }
        })

        await this.updateState({average_booking_busy_times: expanded_busy_times})

      }

    } catch (error) {

      this.handleError(error)
      // console.log(error)

    }

  }



  render () {
    return (
      <div className="time-availability-container">
        {this.state.average_booking_busy_times.map(item => (
          <Tooltip title={item.tooltip}>
            <div className="time-availability" key={item.x_axis_value}>
                <div className="time-availability-top"
                     style={{height: this.state.initialized ? item.height : 0}}>
                </div>
              <div className="time-availability-bottom">{item.x_axis_value}</div>
            </div>
          </Tooltip>
        ))}
      </div>
    )
  }
}