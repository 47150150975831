import ReactComponent from "../../app/ReactComponent";
import {Button, Modal, Typography} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

export class MarketingCommunicationModal extends ReactComponent{
  state = {
    open: false
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Button
          type="link"
          onClick={() => {void this.updateState({open: true})}}><InfoCircleOutlined /></Button>
        <Modal
          title="Souhlas s marketingovou komunikací v souladu s GDPR"
          width={1000}
          className="gdpr-modal"
          open={this.state.open}
          onOk={() => {void this.updateState({open: false})}}
          onCancel={() => {void this.updateState({open: false})}}>

          <p>Vzhledem k tomu, že máme zájem zasílat dárcům hromadně a/nebo opakovaně obchodní sdělení podporující darování krevní plasmy, zpracováváme za účelem přímého marketingu také Vaše kontaktní údaje v rozsahu jméno, příjmení, telefonní číslo, e-mailová adresa a korespondenční adresa. Toto zpracování osobních údajů je nezbytné pro účely oprávněných zájmů Správce spočívající propagaci jeho služeb a činností (jedná se tedy o právní základ dle čl. 6 odst. 1 písm. f) Nařízení GDPR) a je v souladu s ustanovením § 7 zákona č. 480 /2004 Sb., o některých službách informační společnosti a o změně některých zákonů (zákon o některých službách informační společnosti). Při příjmu v dárcovském centru nebo při registraci do Portálu dárce máte právo předem vyjádřit svůj nesouhlas s výše uvedeným využitím Vašich kontaktních údajů a pokud tak učiníte, nebudeme Vám žádné obchodní sdělení zasílat. Pokud svůj nesouhlas nevyjádříte předem, máte právo kdykoliv vznést námitku proti zpracování Vašich osobních údajů pro účely přímého marketingu a pokud tak učiníte, nebudeme je pro účely přímého marketingu (tzn. zasílání obchodních sdělení) nadále využívat. Přístup k Vašim kontaktním údajům budou mít pouze naši pracovníci a případně naši zpracovatelé. Pro tento účel budeme Vaše osobní údaje zpracovávat do ukončení smluvního vztahu vzniklého mezi Vámi a Správcem nebo do vznesení námitky proti zpracování.</p>
          <br/>
        </Modal>

      </>
    )
  }
}