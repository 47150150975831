export const PROPERTY_BOOKINGS_STORE_KEY = 'store'
export const PROPERTY_BOOKINGS_CLOSEST_ALLOWED_DATE_KEY = 'datum_nejblizsi_mozne_rezervace'
export const PROPERTY_BOOKINGS_BLOCKED_DONOR_KEY = 'blokace_darce'
export const PROPERTY_AP_REFERENCE_KEY = 'odkud_o_nas_vite'
export const PROPERTY_AP_INSURANCE_KEY = 'kod_pojistovny'
export const PROPERTY_AP_JOB_KEY = 'p1_it_job'
export const PROPERTY_AP_SOCIAL_ID_KEY = 'rodne_cislo'
export const PROPERTY_AP_PRACTITIONER_KEY = 'prakticky_lekar'
export const PROPERTY_AP_SURVEY_KEY = 'odpovedi'
export const PROPERTY_AP_NIN = 'national_identification_number'
export const PROPERTY_AP_OTHER_REFERENCE_KEY = 'odkud_o_nas_vite_jine_dopnujici_odpoved'
export const PROPERTY_TICKET_NUMBER_KEY = 'p2_ticket_id'
export const PROPERTY_TICKET_CONTACTS_KEY = 'back_contact_method'
export const PROPERTY_TICKET_REASONS_KEY = 'reasons_for_contacting'
export const PROPERTY_TICKET_COMMUNICATION_CHANNEL_KEY = 'communication_channel'
export const PROPERTY_REWARDS_NOTE_KEY = 'request_data_from_customer'
export const PROPERTY_REWARDS_MESSAGE_FOR_CUSTOMER_KEY = 'message_for_customer'
export const PROPERTY_VALIDITY_OF_YEARLY_CHECKUP_KEY = 'platnost_velke_prohlidky_do'
