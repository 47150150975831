import ReactComponent from "../app/ReactComponent"
import {Form, Input, Button, Col, Divider, Row, Space, Image, Layout, Spin} from 'antd'
import {LockOutlined} from '@ant-design/icons'
import {Link} from "react-router-dom";
import {createRef} from "react";

import Mojeamberkey from '../image/moje-amber-key@2x.png'

export class LoginPage extends ReactComponent {
  inputEmail = createRef()
  inputPassword = createRef()

  state = {
    loading_form: false
  }
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.pushPageAnalytics("Přihlášení do Moje Amber")
  }

  async handleLogin(data){

    void this.updateState({loading_form: true})

    await this.props.$auth.authenticate(data)

    void this.updateState({loading_form: false})

  }

  /**
   *  TODO: This method should be removed in the future
   */
  handleLoginTestCustomer() {
    this.props.$auth.authenticateTestUser()
  }

  render() {
    return (
      <>
        <Layout tagName={"main"} className={"login-page"}>
          <Row justify={"center"}>
            <Image src={Mojeamberkey} alt={''} preview={false} width={"368px"}></Image>
          </Row>
          <Row justify={"center"}>
            <Col xs={24} md={20} lg={20} xl={20} style={{maxWidth: '540px'}}>
              <Form name="login" method="post" layout="vertical" onFinish={(...args) => this.handleLogin(...args)}
                    labelCol={{
                      span: 16,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      marginTop: "3em",
                      maxWidth: 540,
                    }}
                    initialValues={{
                      remember: true,
                    }}
              >

                <Form.Item
                  label="Email"
                  name="mail"
                  rules={[{
                    required: true,
                    message: "Prosím vyplňte email."
                  }]}
                >
                  <Input type="email" size="large" placeholder="Zadejte Váš email" autoComplete="on" ref={this.inputEmail}/>
                </Form.Item>

                <Form.Item
                  label="Heslo"
                  name="password"
                  className="password"
                  rules={[{
                      required: true,
                      message: "Prosím vyplňte heslo.",
                  }]}
                >
                  <Input.Password
                    type="password"
                    size="large"
                    placeholder="••••••••"
                    autoComplete="off"
                    ref={this.inputPassword}/>
                </Form.Item>


                <Button type="link" className="recovery-password">
                  <Link to="/lost-password">
                    <LockOutlined/>
                    <span>Zapomenuté heslo</span>
                  </Link>
                </Button>


                <Space style={{marginTop: '30px'}} direction="vertical">

                  {/*<Button onClick={() => this.handleLoginTestCustomer()} type="default" size="large">Přihlásit
                    testovacího uživatele</Button>*/}

                  <Button htmlType="submit" type="primary" className="btn btn-primary" icon={<Spin spinning={this.state.loading_form}/>}>Přihlásit se</Button>
                </Space>
              </Form>
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col xs={24} md={20} lg={20} xl={20} className="dividing-line">
              <Divider orientation="center" plain>
              nebo
              </Divider>
            </Col>
          </Row>
          <Row gutter={[0, 30]} align="center" className="new-account">
            <Col xs={24}>
              <Link to="/registration">
                <Button className="btn btn-secondary">
                  Vytvořit nový účet Moje Amber
                </Button>
              </Link>
            </Col>
            <Col xs={16} md={10} lg={8} xl={6} xxl={4} className="new-account-text">
              Jste náš stávající dárce a nemůžete se přihlásit? <Link to="/lost-password" style={{color: "inherit", textDecoration: "underline"}}>Klikněte zde</Link> a nastavte si heslo.
            </Col>
          </Row>
          <Row>
            <Col xs={0} md={9} lg={11} xl={13} xxl={15} className="hand-drop-image">
              <Image preview={false} src={require("../image/hand-drop-big@2x.png")}></Image>
            </Col>
          </Row>
        </Layout>
      </>
    )
  }
}