import ReactComponent from "../app/ReactComponent";
import {getBloodSamplesEvents} from "../services/events";
import {Alert, Col, Image, Layout, Row, Spin} from "antd";

import VahaImage from '../image/vaha@2x.png'
import PulseImage from '../image/puls@2x.png'
import OdberImage from '../image/odber@2x.png'
import TeplotaImage from '../image/prumerna-teplota@2x.png'
import TlakImage from '../image/tlak@2x.png'
import KapkaImage from '../image/Kapka@2x.png'
import Panacek from '../image/Panacek.svg'
import React from "react";
import {CartesianGrid, Line, LineChart, XAxis, YAxis} from "recharts";
import dayjs from "dayjs";
import {OverallSamplesTable} from "../components/charts/OverallSamplesTable";

class CharacterDot extends ReactComponent {
  render() {
    const {cx, cy} = this.props;

    return (
      this.props.payload.samples > 0 ? <image x={cx - 5} y={cy - 8} width={11} height={15} xlinkHref={KapkaImage}/> : <></>
    );
  }
}


export class HealthPage extends ReactComponent {
  state = {
    loading_page: true,
    loading_chart: true,
    is_empty: false,
    actual_health: {
      weight: "-",
      total_blood_samples: "-",
      temperature: "-",
      pulse: "-",
      blood_pressure: "-"
    },
    blood_samples: {
      items: [],
      sorter: {
        sort_field: 'created_at',
        sort_direction: 'desc',
      },
      pagination: {
        defaultCurrent: 1,
        total: 0,
        defaultPageSize: 100,
        current: 1,
      }
    },
    samples_monthly_chart_data: []
  }

  async componentDidMount() {

    void this.getDataForOverview()

    void this.getDataForHistoryCharts()

  }

  async getDataForOverview() {

    try {

      let {blood_samples, total_items: total_blood_samples} = await getBloodSamplesEvents(0, 1)

      void this.updateState({
        is_empty: (total_blood_samples === 0),
        actual_health: {
          weight: blood_samples[0].properties.weight + " Kg",
          total_blood_samples: total_blood_samples + '.',
          temperature: blood_samples[0].properties.temperature,
          pulse: blood_samples[0].properties.pulse,
          blood_pressure: blood_samples[0].properties.blood_pressure
        }
      })

    } catch (error) {

      console.log(error)

    } finally {

      void this.updateState({loading_page: false})

    }


  }

  async getDataForHistoryCharts() {
    void this.updateState({loading_chart: true})

    try {
      let {blood_samples} = await getBloodSamplesEvents(0, 72)

      void this.setState(prevState => ({
        ...prevState,
        blood_samples: {
          ...prevState.blood_samples,
          items: blood_samples
        }
      }))


      let samples_monthly_chart = {},
        minDate = dayjs().subtract(12, 'month'),
        firstSample = dayjs(blood_samples[blood_samples.length - 1]?.properties?.date)

      minDate = (firstSample.isBefore(minDate)) ? firstSample : minDate

      // Creates empty months values for chart
      while (minDate.isBefore(dayjs())) {
        samples_monthly_chart[minDate.format('YYYY-MM')] = 0
        minDate = minDate.add(1, 'month')
      }


      for (const sample of blood_samples) {

        if (typeof samples_monthly_chart[sample.properties.date.slice(0, 7)] === "undefined")
          samples_monthly_chart[sample.properties.date.slice(0, 7)] = 0

        samples_monthly_chart[sample.properties.date.slice(0, 7)] += 1

      }

      let samples_monthly_chart_data = []

      for (const key of Object.keys(samples_monthly_chart).sort()) {
        samples_monthly_chart_data.push({name: dayjs(key).format('M/YY'), 'samples': samples_monthly_chart[key]})
      }

      void this.updateState({samples_monthly_chart_data})


    } catch (error) {

      console.log(error)

    } finally {

      void this.updateState({loading_chart: false})

    }
  }


  render() {
    return (
      <>
        <Layout tagName={"main"} className="female rewards">
          <Row align="center" justify="center" className="margin-auto" gutter={[{xs: 0, md: 0, lg: 0, xl: 100}, 50]}>
            <Col xs={24} md={24} xl={20}>
              <h1>Zdravotní stav</h1>

              {this.state.is_empty &&
                <Row align="center">
                  <Col xs={24} md={12} xl={8}>
                    <Alert
                      message="Zatím neproběhl žádný odběr."
                      type="warning"
                      banner={true}
                      showIcon
                      style={{marginBottom: '0em'}}
                    />
                  </Col>
                </Row>
              }
            </Col>


            {/*<Col xs={24} md={24} lg={24} xl={8}>
               SILENCIO
            </Col>*/}

            <Col xs={23} md={18} lg={16} xl={16}>
              <Spin spinning={this.state.loading_page} size="large">
                <Row gutter={[0, {xs: 0, md: 40}]}>
                  <Col span={24}>
                    <Row align="center">
                      <Col xs={24} md={12} xl={12}>
                        <div className="list-item-health">
                          <Image src={OdberImage} preview={false}/>
                          <div className="list-item-title">Odběr</div>
                          <div className="list-item-value">
                            {this.state.actual_health.total_blood_samples}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12} xl={12}>

                    <div className="list-item-health">
                      <Image src={TlakImage} preview={false}/>
                      <div className="list-item-title">Krevní tlak</div>
                      <div className="list-item-value">
                        {this.state.actual_health.blood_pressure}
                      </div>
                    </div>

                    <div className="list-item-health">
                      <Image src={TeplotaImage} preview={false}/>
                      <div className="list-item-title">Teplota</div>
                      <div className="list-item-value">
                        {this.state.actual_health.temperature}
                      </div>
                    </div>

                  </Col>
                  <Col xs={24} md={12} xl={12}>

                    <div className="list-item-health">
                      <Image src={VahaImage} preview={false}/>
                      <div className="list-item-title">Váha</div>
                      <div className="list-item-value">
                        {this.state.actual_health.weight}
                      </div>
                    </div>

                    <div className="list-item-health">
                      <Image src={PulseImage} preview={false}/>
                      <div className="list-item-title">Puls</div>
                      <div className="list-item-value">
                        {this.state.actual_health.pulse}
                      </div>
                    </div>

                  </Col>
                </Row>
              </Spin>
            </Col>
            <Col xs={23} md={13} lg={9} xl={16} xxl={14} className="donation-col">
              <Row align="center" gutter={[0, {xs: 0, md: 0}]} className="donation-header">
                <Col xs={23} md={24} xl={24}>
                  <h3>Na vašem darování záleží</h3>
                  <p>Děkujeme za každého dárce, protože každý rok je potřeba</p>
                </Col>
              </Row>
              <Row align="center" className="donation-thanks-text">
                <Col xs={20} md={18} lg={16} xl={8}>
                  <p>Více než</p>
                  <div className="donation-container-image">
                    <span>1 200:</span>
                    <Image preview={false} src={Panacek}></Image>
                  </div>
                  <div>darování plasmy <span className="dontaion-text-bold">k léčbě</span></div>
                  <div><span className="dontaion-text-bold">1 pacienta</span> s hemofilií</div>

                </Col>
                <Col xs={20} md={18} lg={16} xl={8}>
                  <p>Více než</p>
                  <div className="donation-container-image">
                    <span>900:</span>
                    <Image preview={false} src={Panacek}></Image>
                  </div>
                  <div>darování plasmy <span className="dontaion-text-bold">k léčbě</span></div>
                  <div><span className="dontaion-text-bold">1 pacienta</span> s deficitem alpha-1</div>
                    <div>antitrypsinu</div>
                </Col>
                <Col xs={20} md={18} lg={16} xl={8}>
                  <p>Více než</p>
                  <div className="donation-container-image">
                    <span>130:</span>
                    <Image preview={false} src={Panacek}></Image>
                  </div>
                  <div>darování plasmy <span className="dontaion-text-bold">k léčbě</span></div>
                  <div><span className="dontaion-text-bold">1 pacienta</span> s imunodeficiencí</div>
                </Col>
              </Row>

            </Col>

            <Col xs={24} md={24} xl={20}>
              <h4>Měsíční přehled odběrů</h4>

              <div className="monthly-samples-wrapper">
                <div className="monthly-samples-chart-wrapper">
                  <Spin spinning={this.state.loading_chart} size="large">
                    <LineChart
                      width={730}
                      height={250}
                      data={this.state.samples_monthly_chart_data}
                      margin={{top: 0, right: 0, left: 0, bottom: 0}}>


                      <CartesianGrid fill="#ffffff" stroke="#ffe2ec" strokeWidth="1"/>

                      <XAxis
                        type="category"
                        dataKey="name"
                        dy={3}
                        height={30}
                        tick={{fill: "#004B6C", fontSize: 11}}
                        padding={{left: 0, right: 20}}
                        axisLine={false}
                        tickLine={false}/>

                      <YAxis type="number" hide={true} domain={[-0.5, 3.5]}/>

                      <Line type="monotone" dataKey="samples" strokeWidth="2" stroke="#F9AB00" dot={<CharacterDot/>}/>
                    </LineChart>
                  </Spin>
                </div>
                <div className="monthly-samples-label-area">
                  <p className="monthly-samples-label">3 odběry</p>
                  <p className="monthly-samples-label">2 odběry</p>
                  <p className="monthly-samples-label">1 odběr</p>
                  <p className="monthly-samples-label">0 odběrů</p>
                </div>
              </div>
            </Col>

            {!this.state.is_empty &&

              <Col xs={24} md={24} xl={20}>
                <h4>Celkový přehled odběrů</h4>

                <Spin spinning={this.state.loading_chart} size="large">
                  <OverallSamplesTable data={this.state.blood_samples}/>
                </Spin>
              </Col>

            }

          </Row>
        </Layout>

      </>
    )
  }
}