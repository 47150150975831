import React from "react";
import {Typography } from 'antd';

const { Link } = Typography;

export class FooterItems extends React.Component {
  render () {
    return (
      <Link href={this.props.link}>{this.props.text}</Link>
    )
  }
}