import React from 'react'
import ReactDOM from 'react-dom/client'
import Root from './Root'
import reportWebVitals from './reportWebVitals'
import App from "./app/App"
import {createAnalytics, createSSID, createUTM} from "./helpers/global";
import {ConfigProvider} from "antd";
import dayjs from 'dayjs'
import "dayjs/locale/cs"
import updateLocale from "dayjs/plugin/updateLocale"
import locale from "antd/es/locale/cs_CZ"

const app = App.instance

//czech language and datepicker start monday
dayjs.extend(updateLocale)
dayjs.updateLocale("cs", {
  weekStart: 1
})

window.addEventListener('load', () => {

  createUTM()

  createSSID()

  createAnalytics('GTM-M6Z2H6F', "cxDataLayer")

  createAnalytics('GTM-KKHKCRX')

})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


  //<React.StrictMode>
  <ConfigProvider
    locale={locale}
    theme={{
      token: {
        fontFamily: "'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif"
      }
    }}>
    <Root />
  </ConfigProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
