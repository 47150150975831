import ReactComponent from "../app/ReactComponent";

import {
  AP_AGREEMEMT_TYPE_ID,
} from "../helpers/misc";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Radio,
  Result, Row,
  Select, Space,
  Spin, Switch,
  Tooltip
} from 'antd'
import { Link } from 'react-router-dom'
import { CaretDownOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { GDPRModal } from '../components/form/GDPRModal'
import React, { createRef } from 'react'
import { parsePhoneNumber } from 'awesome-phonenumber'
import {getAddressTypeNameByAddressTypeId} from "../services/addresses";
import {fixZIP} from "../helpers/utils";
import App from "../app/App";
import {MarketingCommunicationModal} from "../components/form/MarketingCommunicationModal";

export class ProfilePage extends ReactComponent {
  accountFormRef = createRef()

  state = {
    loading_page: false,
    constants: {
      AP_AGREEMEMT_TYPE_ID,
    },
    account: {},
    bookings: [],
    arrived_alert: false,
    agreement_label: 'Souhlas se zpracováním osobních údajů',
    agreement_text: false,
    agreement_marketing_communication: null,
  }

  async componentDidMount() {

    this.pushPageAnalytics("Můj profil - Moje Amber")

    void this.updateState({loading_page: true})

    void this.getDataForForm()

  }

  async getDataForForm () {
    const countries = await this.$app.$store('common').getCountries()
    const languages = await this.$app.$store('common').getLanguages()
    const address_types = await this.$app.$store('account').getAddressTypes()
    const addresses = await this.$app.$store('account').getAddresses()
    const account = await this.$app.$store('account').getPersonalInfo()

    const { bookings: arrived_bookings = [] } = await this.$api({
      action: 'carecloud_api',
      route: `bookings?booking_statuses[]=${this.state.constants.AP_BOOKING_STATUS_ARRIVED_ID}`
    })

    const { name: agreement_label = false, text: agreement_text = false } = await this.$api({
      action: 'carecloud_api',
      route: `agreements/${this.state.constants.AP_AGREEMEMT_TYPE_ID}`
    })

    await this.updateState({
      loading_page: false,
      account: account,
      countries: countries,
      languages: languages,
      address_types: address_types,
      addresses: addresses,
      arrived_alert: !!arrived_bookings.length,
      agreement_label: (agreement_label ?? this.state.agreement_label),
      agreement_text: (agreement_text ?? this.state.agreement_text),
      agreement_marketing_communication: account.agreement_mc
    })

    this.valuesForFormItems()

  }

  valuesForFormItems() {

    this.accountFormRef.current.setFieldValue("pre_nominals", this.state.account.pre_nominals)
    this.accountFormRef.current.setFieldValue("first_name", this.state.account.first_name)
    this.accountFormRef.current.setFieldValue("last_name", this.state.account.last_name)
    this.accountFormRef.current.setFieldValue("gender", parseInt(this.state.account.gender))
    this.accountFormRef.current.setFieldValue("email", this.state.account.email)
    this.accountFormRef.current.setFieldValue("phone", parsePhoneNumber("+" + this.state.account.phone).number.national)
    this.accountFormRef.current.setFieldValue("phone_prefix", parsePhoneNumber("+" + this.state.account.phone).valid
      ? "+" + parsePhoneNumber("+" + this.state.account.phone).countryCode
      : "")
    this.accountFormRef.current.setFieldValue("birthdate", dayjs(this.state.account.birthdate))
    this.accountFormRef.current.setFieldValue("city", this.state.account.city)
    this.accountFormRef.current.setFieldValue("descnumber", this.state.account.descnumber)
    this.accountFormRef.current.setFieldValue("street", this.state.account.street)
    this.accountFormRef.current.setFieldValue("zip", this.state.account.zip)
    this.accountFormRef.current.setFieldValue("orientnumber", this.state.account.orientnumber)
    this.accountFormRef.current.setFieldValue("agreement_marketing_communication", !this.state.agreement_marketing_communication)

  }

  async sendFormData (data) {

    void this.updateState({loading_page: true})

    try {

      //const agreement_gtc = (+ this.state.agreement_gtc)
      const agreement_marketing_communication = +(this.state.agreement_marketing_communication)
      const phone = (data.phone_prefix + data.phone).replace(/\s/g, '')

      if(!data.email || !data.phone) throw 'Položky označené * jsou povinné.'
      //if(!agreement_gtc) throw 'Souhlas se zpracováním osobních údajů je povinný.'

      await this.$api({
        action: 'carecloud_user_api',
        method: 'PUT',
        data: {
          customer: {
            personal_information: {
              pre_nominals: data.pre_nominals,
              first_name: this.state.account.first_name,
              last_name: this.state.account.last_name,
              gender: this.state.account.gender,
              birthdate: this.state.account.birthdate,
              email: data.email,
              phone: phone,
              address: {
                address1: data.street,
                address2: data.descnumber,
                address3: data.orientnumber,
                city: data.city,
                zip: fixZIP(data.zip),
                country_code: this.state.account.country_code
              },
              agreement: {
                agreement_marketing_communication: agreement_marketing_communication
              },
              language_id: this.state.account.language_id
            }
          }
        }
      })

      // refresh data
      const account = await this.$app.$store('account').getPersonalInfo()

      await this.updateState({
        account: account,
      })

      App.$toast("Údaje byly aktualizovány.")
      //this.$toast.success(this.$t('notices.successfully_updated'))

    } catch (error) {

      //console.log(error)
      this.handleError(error)

    } finally {

      void this.updateState({loading_page: false})

    }

  }

  async sendAddressData (data, id) {

    void this.updateState({loading_page: true})

    try {

      //const data = serializeObject(this.$refs[id][0].$el)

      await this.$api({
        action: 'carecloud_user_api',
        route: `addresses/${id}`,
        method: 'PUT',
        data: {
          additional_address: {
            address_type: data.address_type,
            address: {
              address1: data.street,
              address2: data.descnumber,
              address3: data.orientnumber,
              city: data.city,
              zip: fixZIP(data.zip),
              country_code: 'cz'
            }
          }
        }
      })

      // refresh addresses
      //await this.getAddresses()


      //this.$toast.success(this.$t('notices.successfully_updated'))

    } catch (error) {

      console.log(error)

    } finally {

      void this.updateState({loading_page: false})

    }

  }

  getAddressTypeName (id) {

    return getAddressTypeNameByAddressTypeId(this.address_types, id, this.$tc('common.unknown', 2))

  }

  render() {
    return (
      <Layout tagName="main" className="page-with-title">
        <h1>Můj profil</h1>
        <Row align="center" className="margin-auto">
          <Col xs={23}>
            <Spin spinning={this.state.loading_page} size="large">
              <Card bordered={false} style={{maxWidth: 680}} className="card register">
                {this.state.complete && <Result
                  status="success"
                  title="Registrace byla úspěšná."
                  subTitle="Odkaz pro nastavení hesla Vám přijde na e-mail."/>
                }
                {!this.state.ongoing_alert &&
                  !this.state.blocked_alert &&
                  !this.state.complete &&
                  <Form
                    ref={this.accountFormRef}
                    layout="horizontal"
                    labelCol={{span: 7}}
                    wrapperCol={{span: 17}}
                    onFinish={(...args) => this.sendFormData(...args)}>

                    <div className="form-container">
                      <Form.Item
                        label="Titul"
                        name="pre_nominals"
                      >
                        <Input placeholder="Zadejte titul" size="large"/>
                      </Form.Item>
                      <Form.Item
                        label="Jméno"
                        name="first_name"
                        className="required disabled"
                      >
                        <Input placeholder="Zadejte jméno" size="large" disabled={true}/>
                      </Form.Item>

                      <Form.Item
                        label="Přijmení"
                        name="last_name"
                        className="required disabled"
                        >
                        <Input placeholder="Zadejte přijmení" size="large" disabled={true}/>
                      </Form.Item>

                      <Form.Item label="Pohlaví" name="gender" className="flex-start" >
                        <Radio.Group  disabled={true}>
                          <Radio value={1}>Muž</Radio>
                          <Radio value={2}>Žena</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        label="E-mail"
                        name="email"
                        rules={[{
                          required: true,
                          message: "Prosím vyplňte E-mail"
                        }]}>
                        <Input placeholder="Zadejte váš email" size="large"/>
                      </Form.Item>

                      <Form.Item
                        label="Telefonní číslo"
                        name="phone"
                        rules={[{
                          required: true,
                          message: "Prosím vyplňte Telefonní číslo"
                        }]}>

                        <Input placeholder="Zadejte vaše tel. číslo"
                               addonBefore={<Form.Item name="phone_prefix" initialValue="420" noStyle>
                                 <Select
                                   suffixIcon={<CaretDownOutlined className="ant-select-suffix"/>}
                                   style={{
                                     width: 85,
                                   }}
                                 >
                                   <Select.Option value="420" selected>+420</Select.Option>
                                   <Select.Option value="421">+421</Select.Option>
                                   <Select.Option value="380">+380</Select.Option>
                                 </Select>
                               </Form.Item>}
                               style={{
                                 width: '100%',
                               }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Datum narození"
                        name="birthdate"
                        className="datepicker"
                        rules={[{
                          required: true,
                          message: "Prosím vložte datum narození"
                        }]}>
                        <DatePicker
                          placeholder="    .    ."
                          format="DD.MM.YYYY"
                          size="large"
                          disabled={true}/>
                      </Form.Item>
                    </div>

                    <h4>Doplňující údaje</h4>

                    <div className="form-container">

                      <Form.Item label="Ulice" name="street" className="GoogleAutocompleteAddressTrigger">
                        <Input placeholder="Zadejte část adresy" size="large"/>
                      </Form.Item>

                      <Form.Item label="Číslo popisné / orientační" className="contact-descnumber flex-start">
                        <Form.Item name="descnumber" style={{display: 'inline-block', width: 100}}>
                          <Input placeholder="" size="large"/>
                        </Form.Item>
                        <span style={{margin: 'auto 5px'}}>/</span>
                        <Form.Item name="orientnumber" style={{display: 'inline-block', width: 100}}>
                          <Input placeholder="" size="large"/>
                        </Form.Item>
                      </Form.Item>

                      <Form.Item label="PSČ" name="zip" className="flex-start">
                        <Input placeholder="" size="large" style={{ width: 100 }} />
                      </Form.Item>

                      <Form.Item label="Město" name="city">
                        <Input placeholder="" size="large"/>
                      </Form.Item>

                      <Form.Item className="consent">
                        <Form.Item
                          name="agreement_marketing_communication"
                          className="switch-container"
                        >
                          <Switch
                            id="agreement_marketing_communication"
                            checked={!this.state.agreement_marketing_communication}
                            style={{marginRight: '0.9em'}}
                            onChange={(data) => {void this.updateState({agreement_marketing_communication: !data})}}/>
                        </Form.Item>
                        <label htmlFor="agreement_marketing_communication" className="required">Nesouhlasím s marketingovou komunikací (zasíláním obchodních sdělení) <MarketingCommunicationModal /></label>
                      </Form.Item>
                    </div>

                    <Space style={{marginTop: '30px'}} size={[12, 10]} className="btn-container">
                      <Link to="/">
                        <Button type="text" className="btn-cancel" icon={<CloseOutlined/>}>Zrušit změny</Button>
                      </Link>
                      <Button htmlType="submit" type="primary" className="btn btn-secondary" icon={<Spin spinning={this.state.loading_page} />} style={{minWidth: 152, width: 152}}>Uložit</Button>
                    </Space>

                  </Form>
                }
              </Card>
            </Spin>
          </Col>
        </Row>

      </Layout>
    )
  }
}