export default class Query {

	constructor (endpoint) {

		this.endpoint = [endpoint + "?"]
		this.params = []

	}

	add (key, value) {

		if(Array.isArray(value))
			for(const item of value)

				this.params.push(`${key}[]=${item}`)

		else

			this.params.push(`${key}=${value}`)

		return this
	}

	get route () {

		return this.endpoint.concat(this.params).join('&')

	}

}