import Query from "../app/Query";
import App from "../app/App";

export const EVENT_PROPERTY_WEIGHT = 'p1_vaha'
export const EVENT_PROPERTY_PULSE = 'p1_tep'
export const EVENT_PROPERTY_TEMPERATURE = 'p1_teplota'
export const EVENT_PROPERTY_BLOOD_PRESSURE = 'p1_tlak'
export const EVENT_PROPERTY_DATE = 'p1_datum_odberu'

export async function getBloodSamplesEvents (offset = 0, limit = 10) {

  const query = new Query('events')

  query.add('event_type_ids', ["8cc89f128a48808e4eb9721a86", "8ccacd16928931fd2fe06facd2"])
  query.add('sort_field', 'created_at')
  query.add('sort_direction', 'desc')
  query.add('offset', offset)
  query.add('count', limit)

  const {customer_events, total_items} = await App.instance.$api({
    action: "carecloud_user_api",
    route: query.route
  })

  for (const event of customer_events) {

    const { property_records } = await App.instance.$api({
      action: "carecloud_api",
      route: `events/${event.event_id}/property-records`
    })

    event.properties = {
      weight: getEventPropertyValue(EVENT_PROPERTY_WEIGHT, property_records),
      temperature: getEventPropertyValue(EVENT_PROPERTY_TEMPERATURE, property_records),
      pulse: getEventPropertyValue(EVENT_PROPERTY_PULSE, property_records),
      blood_pressure: getEventPropertyValue(EVENT_PROPERTY_BLOOD_PRESSURE, property_records),
      date: getEventPropertyValue(EVENT_PROPERTY_DATE, property_records)
    }
  }

  return {blood_samples: customer_events, total_items}

}

function getEventPropertyValue(name, event_properties) {
  let value = event_properties.find(element => element.property_id === name)?.property_value
  return (value === "" || value === null || typeof value === "undefined") ? "-" : value
}