import App from "../app/App";

export class CommonResource {
  data = {
    countries: [],
    languages: [],
    stores: [],
    currencies: []
  }

  constructor() {
    this.$app = App.instance;
    this.$api = this.$app.$api
    this.$resource = this.$app.$resource

  }

  async getCountries() {

    try {

      const {countries = []} = await this.$resource('countries', {
        action: 'carecloud_api',
        route: 'countries'
      })

      return countries

    } catch (error) {

      console.log(error)

    }

  }

  async getLanguages() {

    try {

      const {languages = []} = await this.$resource('languages', {
        action: 'carecloud_api',
        route: 'languages?language_list_type=customer-communication'
      })

      return languages

    } catch (error) {

      console.log(error)

    }

  }

  async getStores() {

    try {

      const {stores = []} = await this.$resource('stores', {
        action: 'carecloud_api',
        route: 'stores'
      })

      return stores

    } catch (error) {

      console.log(error)

    }

  }

  async getCurrencies() {

    try {

      const {currencies = []} = await this.$resource('currencies', {
        action: 'carecloud_api',
        route: 'currencies'
      })

      return currencies

    } catch (error) {

      console.log(error)

    }
  }
}