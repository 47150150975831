import {fixNullString} from "../helpers/utils";
import App from "../app/App";
import {AP_SURVEY_ALLOWED_SEGMENTS} from "../helpers/misc";

export class AccountResource {
  data = {
    pre_nominals: '',
    first_name: '',
    last_name: '',
    gender: '',
    birthdate: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    zip: '',
    country_code: '',
    photo_url: null,
    agreement_gtc: 0,
    agreement_profiling: 0,
    agreement_marketing_communication: 0,
    language_id: '',
    current_status: undefined,
    available_points: undefined,
    available_credits: undefined,
    address_types: [],
    addresses: [],
    properties: [],
    loading_error: false
  }

  constructor() {
    this.$app = App.instance;
    this.$api = this.$app.$api
    this.$resource = this.$app.$resource

  }

  async getPersonalInfo () {

    try {

      const { personal_information } = await this.$api({
        action: 'carecloud_user_api'
      })

      const agreement_gtc = personal_information.agreement.agreement_gtc
      const agreement_mc = personal_information.agreement.agreement_marketing_communication
      const agreement_profiling = personal_information.agreement.agreement_profiling

      this.data = {
        ...this.data,
        pre_nominals: personal_information.pre_nominals,
        first_name: personal_information.first_name,
        last_name: personal_information.last_name,
        gender: fixNullString(personal_information.gender),
        birthdate: personal_information.birthdate,
        email: personal_information.email,
        phone: personal_information.phone,
        street: personal_information.address.address1,
        descnumber: personal_information.address.address2,
        orientnumber: personal_information.address.address3,
        city: personal_information.address.city,
        zip: personal_information.address.zip,
        country_code: personal_information.address.country_code,
        photo_url: personal_information.photo_url,
        language_id: personal_information.language_id,
        loading_error: false,
        agreement_gtc,
        agreement_mc,
        agreement_profiling
      }

      return this.data

    } catch (error) {

      const { title } = error

      //if(title)commit('setLoadingError', title)

      console.log(error)

    }

  }

  async getStatus () {

    try {

      const { statuses = [] } = await this.$resource('statuses', {
        action: 'carecloud_api',
        route: 'statuses'
      })

      const { status_records = [] } = await this.$api({
        action: 'carecloud_user_api',
        route: 'status-records?valid_now=true'
      })

      const { status_id: current_status_id = 0 } = (status_records.at(0) ?? {})
      const { name: status_name = 'Neznámý' } = (statuses.find(({status_id}) => status_id === current_status_id) ?? {})

      return status_name

    } catch (error) {

      console.log(error)

    }

  }

  async isAllowedToShowSurvey () {

    const { segment_records = [] } = await this.$api({
      action: 'carecloud_user_api',
      route: 'segment-records'
    })

    return segment_records.some(({segment_id}) => AP_SURVEY_ALLOWED_SEGMENTS.includes(segment_id))
  }

  async getPoints () {

    try {

      const { available_points = 0 } = await this.$api({
        action: 'carecloud_api',
        route: `wallet/actions/points-overview`
      })

      return available_points

    } catch (error) {

      console.log(error)

    }

  }

  async getCredits () {

    try {

      const { available_credits = 0 } = await this.$api({
        action: 'carecloud_api',
        route: `wallet/actions/credits-overview`
      })

      return available_credits

    } catch (error) {

      console.log(error)

    }

  }

  async getAddressTypes () {

    try {

      const { customer_address_types = [] } = await this.$resource('customer-address-types', {
        action: 'carecloud_api',
        route: 'customer-address-types'
      })

      return customer_address_types

    } catch (error) {

      console.log(error)

    }

  }

  async getAddresses () {

    try {

      const { addresses = [] } = await this.$api({
        action: 'carecloud_user_api',
        route: 'addresses'
      })

      return addresses

    } catch (error) {

      console.log(error)

    }

  }

  async getProperties () {

    try {

      const { property_records = [] } = await this.$api({
        action: 'carecloud_user_api',
        route: 'property-records'
      })

      return property_records

    } catch (error) {

      console.log(error)

    }
  }
}