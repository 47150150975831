import {Steps, Button} from "antd"
import React from "react"
import {YesOrNoRadios, SurveyTextarea, Survey} from "./Survey"
import {AP_SURVEY_EVENT_TYPE_ID} from "../helpers/misc"
import {AP_SURVEY_QUESTIONS_REGISTRATION} from "../helpers/misc_survey"

export class SurveyRegistration extends Survey {
  state = {
    loading_page: false,
    current: 0,
    bigQuestions: [24],
    questions: AP_SURVEY_QUESTIONS_REGISTRATION,
    constants: {
      AP_SURVEY_EVENT_TYPE_ID
    }
  }

  async componentDidMount() {

    void this.getDataForForm()
  }

render() {
  return (<>
      <Steps
        direction="vertical"
        current={this.state.current}
        onChange={this.changeSurveyStep}
        items={[
          {
            className: this.state.questions.q1.answer !== undefined ? "step-open-by-default" : null,
            title: this.props.gender !== "2" ? this.state.questions.q1.question : this.state.questions.q1.question_female,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q2.question : this.state.questions.q2.question_female,
            className: this.state.questions.q2.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q2 === true &&

                <SurveyTextarea skey={"q2z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q3.question : this.state.questions.q3.question_female,
            className: this.state.questions.q3.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q3"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q4.question : this.state.questions.q4.question_female,
            className: this.state.questions.q4.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q4"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q5.question : this.state.questions.q5.question_female,
            className: this.state.questions.q5.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q5"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q5 === true &&

                <SurveyTextarea skey={"q5z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q6.question : this.state.questions.q6.question_female,
            className: (
              this.state.questions.q6z1.answer !== undefined ||
              this.state.questions.q6z2.answer !== undefined ||
              this.state.questions.q6z3.answer !== undefined
            ) ? "step-open-by-default" : null,
            description: <div>
                  <div className="mi-3">
                    <label>{this.state.questions.q6z1.question}</label>
                    <YesOrNoRadios skey={"q6z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  </div>
                  <div className="mi-3">
                    <label>{this.state.questions.q6z2.question}</label>
                    <YesOrNoRadios skey={"q6z2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  </div>
                  {this.props.gender !== "2" &&
                    <div className="mi-3">
                      <label>{this.state.questions.q6z3.question}</label>
                      <YesOrNoRadios skey={"q6z3"} questions={this.state.questions}
                                     setSurveyAnswer={this.setSurveyAnswer}/>
                    </div>
                  }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q7.question : this.state.questions.q7.question_female,
            className: this.state.questions.q7.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q7"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q7 === true &&
                <>
                  <SurveyTextarea skey={"q7z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  <SurveyTextarea skey={"q7z2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                </>
              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q8.question : this.state.questions.q8.question_female,
            className: this.state.questions.q8.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q8"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q9.question : this.state.questions.q9.question_female,
            className: this.state.questions.q9.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q9"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q9 === true &&

                <>
                  <SurveyTextarea skey={"q9z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  <SurveyTextarea skey={"q9z2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                </>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q10.question : this.state.questions.q10.question_female,
            className: this.state.questions.q10.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q10"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q10 === true &&

                <SurveyTextarea skey={"q10z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q11.question : this.state.questions.q11.question_female,
            className: this.state.questions.q11.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q11"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q12.question : this.state.questions.q12.question_female,
            className:  this.state.questions.q12.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q12"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q12 === true &&

                <SurveyTextarea skey={"q12z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q13.question : this.state.questions.q13.question_female,
            className: this.state.questions.q13.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q13"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q14.question : this.state.questions.q14.question_female,
            className: this.state.questions.q14.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q14"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q15.question : this.state.questions.q15.question_female,
            className: this.state.questions.q15.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q15"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q16.question : this.state.questions.q16.question_female,
            className: this.state.questions.q16.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q16"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q16 === true &&

                <SurveyTextarea skey={"q16z"} gender={this.props.gender} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q17.question : this.state.questions.q17.question_female,
            className: this.state.questions.q17.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q17"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q18.question : this.state.questions.q18.question_female,
            className: this.state.questions.q18.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q18"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q19.question : this.state.questions.q19.question_female,
            className: this.state.questions.q19.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q19"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q20.question : this.state.questions.q20.question_female,
            className: this.state.questions.q20.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q20"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q21.question : this.state.questions.q21.question_female,
            className: this.state.questions.q21.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q21"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q22.question : this.state.questions.q22.question_female,
            className: this.state.questions.q22.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q22"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q23.question : this.state.questions.q23.question_female,
            className: this.state.questions.q23.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q23"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q23 === true &&

                <SurveyTextarea skey={"q23z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q24.question : this.state.questions.q24.question_female,
            className: this.state.questions.q24.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q24"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q25.question : this.state.questions.q25.question_female,
            className: (this.state.questions.q25a.answer !== undefined ||
              this.state.questions.q25b.answer !== undefined ||
              this.state.questions.q25c.answer !== undefined ||
              this.state.questions.q25d.answer !== undefined ||
              this.state.questions.q25e.answer !== undefined ||
              this.state.questions.q25f.answer !== undefined ||
              this.state.questions.q25g.answer !== undefined ||
              this.state.questions.q25i.answer !== undefined ||
              this.state.questions.q25j.answer !== undefined ||
              this.state.questions.q25k.answer !== undefined ||
              this.state.questions.q25l.answer !== undefined ||
              this.state.questions.q25m.answer !== undefined ||
              this.state.questions.q25n.answer !== undefined ||
              this.state.questions.q25o.answer !== undefined ||
              this.state.questions.q25p.answer !== undefined ||
              this.state.questions.q25q.answer !== undefined ||
              this.state.questions.q25r.answer !== undefined ||
              this.state.questions.q25s.answer !== undefined ||
              this.state.questions.q25t.answer !== undefined ||
              this.state.questions.q25u.answer !== undefined ||
              this.state.questions.q25v.answer !== undefined ||
              this.state.questions.q25w.answer !== undefined ||
              this.state.questions.q25x.answer !== undefined ||
              this.state.questions.q25y.answer !== undefined ||
              this.state.questions.q25z.answer !== undefined ||
              this.state.questions.q25z1.answer !== undefined ||
              this.state.questions.q25z2.answer !== undefined
            ) ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <div className="q26-subtitle">a) Mozek nebo nervový systém</div>
              <div className="mi-3">
                <label>{this.state.questions.q25a.question}</label>
                <YesOrNoRadios skey={"q25a"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q25b.question}</label>
                <YesOrNoRadios skey={"q25b"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q25c.question}</label>
                <YesOrNoRadios skey={"q25c"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q25d.question}</label>
                <YesOrNoRadios skey={"q25d"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q25e.question}</label>
                <YesOrNoRadios skey={"q25e"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q25f.question}</label>
                <YesOrNoRadios skey={"q25f"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">b) Srdce a krevní oběh</div>
              <div className="mi-3">
                <label>{this.state.questions.q25i.question}</label>
                <YesOrNoRadios skey={"q25i"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">c) Zhoubné nádory</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q25j"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">d) Dýchací orgány</div>
              <div className="mi-3">
                <label>{this.state.questions.q25l.question}</label>
                <YesOrNoRadios skey={"q25l"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">e) Zažívací ústrojí</div>
              <div className="mi-3">
                <label>{this.state.questions.q25n.question}</label>
                <YesOrNoRadios skey={"q25n"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">f) Močové cesty a pohlavní orgány</div>
              <div className="mi-3">
                <label>{this.state.questions.q25p.question}</label>
                <YesOrNoRadios skey={"q25p"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q25q.question}</label>
                <YesOrNoRadios skey={"q25q"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">g) Onemocnění jater</div>
              <div className="mi-3">
                <label>{this.state.questions.q25s.question}</label>
                <YesOrNoRadios skey={"q25s"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">h) Malárie nebo jiná tropická onemocnění</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q25t"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">i) Jiná infekční a horečnatá onemocnění (případně kontakt s nimi)</div>
              <div className="mi-3">
                <label>{this.state.questions.q25v.question}</label>
                <YesOrNoRadios skey={"q25v"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">j) Onemocnění štítné žlázy nebo slinivky břišní</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q25w"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">k) Poruchy krevní srážlivosti (krvácivost nebo trombózy)</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q25x"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">l) Kožní onemocnění</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q25y"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">m) Cukrovka, dna, zvýšený cholesterol</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q25z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">o) Zánětlivé onemocnění kloubů</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q25z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">p) Poruchy imunity (včetně autoimunitních onemocnění)</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q25z2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q26.question : this.state.questions.q26.question_female,
            className: this.state.questions.q26.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q26"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q27.question : this.state.questions.q27.question_female,
            className: this.state.questions.q27.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q27"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q27 === true && <>

                <SurveyTextarea skey={"q27z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              </>}

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          this.props.gender !== "1" && {
            title: this.props.gender !== "2" ? this.state.questions.q28.question : this.state.questions.q28.question_female,
            className: this.state.questions.q28.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q28"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
        ]}
      />
    </>)
  }
}