import ReactComponent from '../../app/ReactComponent'
import { Tag } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

export class ReservationTag extends ReactComponent {

  iconViews() {
    const text_id = this.getTextID()
    if (text_id === "odbaven" || text_id === "aktivni" || text_id === "prisel")
      return <CheckOutlined />
    else
      return <CloseOutlined />
  }

  getTextID = () => {
    return this.props.status.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ','')
  }


  render () {
    return (
      <>
        <Tag
          className={this.getTextID()}
          icon={this.iconViews()}
        >
          {this.props.status}
        </Tag>
      </>
    )
  }
}