import ReactComponent from '../../app/ReactComponent'
import { Button, Drawer, Image } from 'antd';
import React from "react"
import {Link} from "react-router-dom";

const closeIcon = <Image preview={false} src={require('../../image/Close@2x.png')} />

export class Menu extends ReactComponent {

  state = {
    current: 'mail',
    visible : false
  };

  handleLogout () {
    this.props.$auth.logout()
    this.close()
  }

  open = () => {
    this.setState({visible:true});
  };

  close = () => {

    this.scrollToTop()

    this.setState({visible:false});

  };

  handleClick = e => {
    this.setState({ current: e.key });
  };

  render() {
    const menuLogo = <Link to={"/"} onClick={this.close}><Image preview={false} src={require('../../image/logo-mobil@2x.png')}/></Link>
    const { current } = this.state;
    return (
        <>
          <Button type="button" onClick={this.open} className="hamburger-menu">
            <Image preview={false} src={require('../../image/Menu@2x.png')} />
          </Button>
          <Drawer className="menu-drawer" onClick={this.handleClick} title={menuLogo} placement="right" onClose={this.close} open={this.state.visible} closeIcon={closeIcon}>
            <div style={{display:'flex', flexDirection:"column"}}>
              <Link to="/" onClick={this.close}><Button type="text">Domů</Button></Link>
              <Link to="/reservation" onClick={this.close}><Button type="text">Rezervace odběru</Button></Link>
              <Link to="/reservations" onClick={this.close}><Button type="text">Přehled rezervací</Button></Link>
              <Link to="/health" onClick={this.close}><Button type="text">Zdravotní stav</Button></Link>
              <Link to="/rewards" onClick={this.close}><Button type="text">Amber konto</Button></Link>
              <Link to="/profile" onClick={this.close}><Button type="text">Můj profil</Button></Link>
              <Link to="/" onClick={() => this.handleLogout()}><Button type="text">Odhlásit</Button></Link>
            </div>
            <div className="menu-moje-amber-image">
              <Image preview={false} src={require('../../image/moje-amber@2x.png')}></Image>
            </div>
          </Drawer>
        </>
    )
  }
}