import Cookies from 'js-cookie'
import {getScript} from "./utils";

export const UTM_COOKIE_NAME = 'utm_params'
const UTM_COOKIE_VALIDITY = 14 // days

export function createUTM () {

	if(Cookies.get(UTM_COOKIE_NAME)) return

	const url = new URL(window.location)
	const { searchParams } = url

	const has_params = (searchParams.has('utm_campaign') && searchParams.has('utm_medium') && searchParams.has('utm_source'))

	if(has_params) {

		Cookies.set(UTM_COOKIE_NAME, JSON.stringify({
			utm_campaign: searchParams.get('utm_campaign'),
			utm_medium: searchParams.get('utm_medium'),
			utm_source: searchParams.get('utm_source')
		}), {
			expires: UTM_COOKIE_VALIDITY
		})

	}

}

export function getQueryParameter (key) {
	const url = new URL(window.location)
	const { searchParams } = url

	return searchParams.get(key) ?? null

}

export function createAnalytics (GID, dataLayer = "dataLayer") {

	(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script',dataLayer,GID)


}

export function pushAnalytics (data, dataLayer = "dataLayer") {

	window[dataLayer] = (window[dataLayer] || [])
	window[dataLayer].push(data)

}

export const SSID_COOKIE_NAME = 'store_system_id'
const SSID_COOKIE_VALIDITY = 30 // days

export function createSSID () {

	const url = new URL(window.location)
	const { searchParams } = url

	if(searchParams.has('storeid')) {

		Cookies.set(SSID_COOKIE_NAME, searchParams.get('storeid'), {
			expires: SSID_COOKIE_VALIDITY
		})

	}

}
