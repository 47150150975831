import React from "react";

import { Typography, Layout, Space, Row, Col, Image, Collapse } from 'antd';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import {FooterItems} from './FooterItem'

import logo from '../image/logo-desktop.svg'

const { Text, Link } = Typography;
const Panel = Collapse.Panel;

const AMBERPLASMAURL = "https://www.amberplasma.com/"

const items = [
  {link: "proc-darovat/",text: "Proč darovat", id: "1" },
  {link: "stante-se-darcem/",text: "Staňte se dárcem", id: "2" },
  {link: "system-odmen/",text: "Systém odměn", id: "3" },
  {link: "akce-pro-darce/",text: "Akce pro dárce", id: "4" },
  {link: "darcovska-centra/",text: "Dárcovská centra", id: "5" },
  {link: "faq/",text: "FAQ", id: "6" },
  {link: "o-nas/",text: "O nás", id: "7" },
  {link: "novinky/",text: "Novinky", id: "8" },
  {link: "kariera/",text: "Kariéra", id: "9" },
  {link: "gdpr/",text: "GDPR", id: "10" },
]

const listItem = items.map(item =>
  (
    <div className="footer-item" key={item.id}>
      <FooterItems link={AMBERPLASMAURL + item.link} text={item.text}/>
    </div>
  )
)

export class Footer extends React.Component {
  state = {
    footer_links: [
      {link: "proc-darovat/", text: "Proč darovat"}
    ]
  }
  render () {
    return (
      <Layout tagName={"footer"}>
        <Row justify="center" className="footer-row first-row">
          <Col xs={24} md={6} className="footer-logo">
            <Link href={AMBERPLASMAURL}>
              <Image preview={false} src={logo}></Image>
            </Link>
          </Col>
          <Col xs={0} md={6}>
            {listItem}
          </Col>
          <Col xs={16} md={0}>
            <Collapse
              ghost
              items={[{label: "Zobrazit menu", children: listItem, id: 0}]}
            />
          </Col>
          <Col xs={12} md={6}>
            <Space direction="vertical">
              <Typography.Title level={3} className="footer-h3">
                Bezplatná linka
              </Typography.Title>
              <Link className="footer-tel" href="tel:+420800606806">800 606 806</Link>
              <Text className="footer-text">Bezplatná linka je dostupná v pracovních dnech od 7:30 do 17:00.
                Mimo pracovní dobu této linky, kontaktujte konkrétní pobočku.
              </Text>
            </Space>
          </Col>
          <Col xs={12} md={6}>
            <Typography.Title level={3} className="footer-h3">
              Společnost
            </Typography.Title>
            <Text className="footer-text">
              Amber Plasma a.s.
              <br/>
              V hůrkách 2145/1,
              <br/>
              Stodůlky, 158 00 Praha 5
              <br/>
              <br/>
              IČO: 27829049
              <br/>
              DIČ: CZ27829049
              <br/>
              <br/>
              Společnost je uvedena v obchodním rejstříku pod spisovou značkou B 14786
            </Text>
          </Col>
        </Row>
        <Row justify="center" className="last-row">
          <Col span={8}>
            <Text>Amber Plasma, a.s.</Text>
          </Col>
          <Col span={8}>
            <Space>
              <Link href="https://www.facebook.com/AmberPlasma/">
                <FaFacebookF />
              </Link>
              <Link href="https://www.instagram.com/amberplasma/?hl=cs" className="instagram">
                <FaInstagram />
              </Link>
            </Space>
          </Col>
          <Col span={8}>
            <Text>Created by <Link href="https://www.cortex.cz/">Cortex, a.s.</Link></Text>
          </Col>
        </Row>
      </Layout>
    )
  }
}