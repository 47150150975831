import ReactComponent from "../../app/ReactComponent";
import {Button, Image, Popconfirm} from "antd";
import dayjs from "dayjs";
import {AP_BOOKING_STATUS_ACTIVE_ID, AP_BOOKING_STATUS_ARRIVED_ID} from "../../helpers/misc";
import { DeleteOutlined } from '@ant-design/icons'

export class CancelRewardsButton extends ReactComponent {
  state = {
    open: false,

  }

  constructor(props) {
    super(props);
  }



  render() {
    return (
      <Popconfirm
        title={this.props.title}
        description={this.props.description}
        okText={"Ano"}
        cancelText={"Ne"}
        icon={false}
        onConfirm={() => {this.props.onConfirm(this.props.item)}}
        disabled={this.props.disabled}
      >
        <Button
          type="link"
          danger
          disabled={this.props.disabled}
          onClick={() => {void this.updateState({open: !this.state.open})}}
          icon={<DeleteOutlined />}
        >Zrušit</Button>
      </Popconfirm>
    )
  }
}