import ReactComponent from "../app/ReactComponent"
import {Link} from "react-router-dom";
import {Space, Layout, Row, Col, Image, Typography, Spin, Button} from "antd";
import {
  AP_BOOKING_STATUS_ACTIVE_ID,
  AP_BOOKING_STATUS_ARRIVED_ID,
  AP_BOOKING_STATUS_RESOLVED_ID
} from "../helpers/misc";
import Query from "../app/Query";
import {mapBookings} from "../services/bookings";
import {PROPERTY_BOOKINGS_STORE_KEY} from "../helpers/properties";
import dayjs from "dayjs";
import {ArrowRightOutlined} from "@ant-design/icons";

const {Text, Title} = Typography

export class DashboardPage extends ReactComponent {
  state = {
    loading_page: true,
    gender: 1,
    salutation: "",
    constants: {
      AP_BOOKING_STATUS_ARRIVED_ID,
      AP_BOOKING_STATUS_ACTIVE_ID,
      AP_BOOKING_STATUS_RESOLVED_ID,
    },
    previous_bookings: [],
    ongoing_booking: null,
    show_survey_btn: false,
  }

  constructor(props) {
    super(props)

  }

  async componentDidMount() {
    this.scrollToTop()
    this.pushPageAnalytics("Moje Amber")

    try {

      const account = await this.$api({
        action: "carecloud_user_api",
        route: "?customer_short_salutation=true"
      })

      const query = new Query('bookings')

      query.add('sort_field', 'created_at')
      query.add('sort_direction', 'desc')
      query.add('add_booking_items', 'full')

      const {bookings = []} = await this.$api({
        action: 'carecloud_api',
        route: query.route
      })

      await this.updateState({
        previous_bookings: mapBookings(bookings),
        gender: account.personal_information.gender,
        salutation: account.personal_information.salutation,
        loading_page: false
      })


      await this.checkForOngoingBookings()

      // PREPARE DATA FOR ONGOING BOOKING
      if (this.state.ongoing_booking)
        await this.updateState({
          ongoing_booking: {
            store_name: this.getBookingStoreName(this.state.ongoing_booking.additional_properties),
            date: dayjs(this.state.ongoing_booking.valid_from).format("D.M.YYYY"),
            time: dayjs(this.state.ongoing_booking.valid_from).format("H:mm"),
          }
        })

      void this.updateState({
        show_survey_btn: await this.$app.$store('account').isAllowedToShowSurvey()
      })
    } catch(error) {
      this.handleError(error)
    } finally {

    }

  }

  getBookingStoreName(additional_properties = []) {

    const {property_value: booking_stores = []} = (additional_properties.find(({property_id}) => property_id === PROPERTY_BOOKINGS_STORE_KEY) ?? {})
    const store_property_value = (booking_stores.length ? booking_stores.map(({name}) => name) : [this.$app.$tc('common', 'unknown', 0)])

    return store_property_value[0].split(", ").slice(1, 2).join(", ")

  }

  async checkForOngoingBookings() {

    const ongoing_statuses = [
      this.state.constants.AP_BOOKING_STATUS_ARRIVED_ID,
      this.state.constants.AP_BOOKING_STATUS_ACTIVE_ID
    ]

    const ongoing_bookings = this.state.previous_bookings.filter(({booking_item_status}) => ongoing_statuses.includes(booking_item_status))

    if (ongoing_bookings.length > 0)
      await this.updateState({ongoing_booking: ongoing_bookings[0]})
  }

  render() {
    return (
      <Spin spinning={this.state.loading_page} size={"large"}>
        <Layout tagName="main"
                className={"dashboard " + (this.state.gender === 2 ? "female" : "male")}>
          <Row align="top" justify="center" gutter={[20, 20]}>
            <Col xs={0} md={24} lg={12} xl={8} className="left-col">
              <Space direction="vertical">
                <Image preview={false} src={require('../image/dashboard/moje-amber@2x.png')}></Image>
                <Image preview={false}
                       src={this.state.gender === 2 ? require('../image/dashboard/woman.png') : require('../image/dashboard/man@2x.png')}></Image>
                <Typography.Title level={4}>Vítejte v portálu dárce,
                  <br/>
                  <Text>{this.state.salutation}</Text>
                </Typography.Title>
              </Space>
            </Col>
            <Col xs={24} md={0} className="left-col-mobile">
              <Space direction="vertical">
                <Image preview={false} src={require('../image/dashboard/moje-amber@2x.png')}></Image>
                <Typography.Title level={4}>Vítejte v portálu dárce, {this.state.salutation}
                </Typography.Title>
              </Space>
            </Col>
            <Col md={24} lg={12} xl={9} xxl={7} className="right-col">
              <Row>
                <Col>
                  {this.state.ongoing_booking &&

                    <Space className="right-col-link" size={[0,0]}>
                      <Link to="/reservations" style={{color: "inherit"}}>
                        <p style={{lineHeight: 1.5}}><strong>{this.state.ongoing_booking.date}</strong><br/>
                          v {this.state.ongoing_booking.time} h<br/>
                          {this.state.ongoing_booking.store_name}</p>

                        <Typography.Title level={5} style={{marginTop: 0}}>Rezervováno</Typography.Title>
                      </Link>
                    </Space>

                  }

                  {!this.state.ongoing_booking &&

                    <Space className="right-col-link" size={[0,0]}>
                      <Link to="/reservation">
                        <Image preview={false} src={require('../image/dashboard/Hodiny@2x.png')}></Image>
                        <Typography.Title level={4}>Rezervace odběru</Typography.Title>
                      </Link>
                    </Space>

                  }

                </Col>


                <Col>
                  <Space className="right-col-link" size={[0,0]}>
                    <Link to="/health">
                      <Image preview={false} src={require('../image/Zdravotni-stav@2x.png')}></Image>
                      <Typography.Title level={4}>Zdravotní stav</Typography.Title>
                    </Link>
                  </Space>
                </Col>

                <Col>
                  <Space className="right-col-link" size={[0,0]}>
                    <Link to="/profile">
                      <Image preview={false} src={require('../image/dashboard/Muj-profil@2x.png')}></Image>
                      <Typography.Title level={4}>Můj profil</Typography.Title>
                    </Link>
                  </Space>
                </Col>

                <Col>
                  <Space className="right-col-link" size={[0,0]}>
                    <Link to="/rewards">
                      <Image preview={false} src={require('../image/dashboard/Amber-konto@2x.png')}/>
                      <Typography.Title level={4}>Amber konto</Typography.Title>
                    </Link>
                  </Space>
                </Col>

                {/*
                {this.state.show_survey_btn &&

                  <>
                    <Col span={24} style={{margin: "2em 0 0 0"}}>
                      <Link to={"/survey"}>
                        <Button type="primary" className="btn btn-secondary">Je nutné vyplnit formulář <ArrowRightOutlined style={{marginLeft: "1em"}}/></Button>
                      </Link>
                    </Col>
                  </>

                }
                */}

                <Col span={24} className="advertising">
                  <Space style={{margin: "0.5em", maxWidth: 339}} size={[0,0]}>
                    <div>
                      <p align="left" className="mb-1 mt-4">Reklama</p>
                      <Image className="" preview={false} src={require('../image/339x280_banner_portal_doved_darce.png')} />
                    </div>
                  </Space>
                </Col>

              </Row>
            </Col>
          </Row>
        </Layout>
      </Spin>
    )
  }
}