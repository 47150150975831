import {Steps, Button} from "antd"
import React from "react"
import {YesOrNoRadios, SurveyTextarea, Survey} from "./Survey"
import {AP_SURVEY_EVENT_TYPE_ID} from "../helpers/misc"
import {AP_SURVEY_QUESTIONS_GUIDE} from "../helpers/misc_survey"

export class SurveyGuide extends Survey {

  state = {
    loading_page: false,
    current: 0,
    bigQuestions: [3],
    questions: AP_SURVEY_QUESTIONS_GUIDE,
    constants: {
      AP_SURVEY_EVENT_TYPE_ID
    }
  }

  async componentDidMount() {

    void this.getDataForForm()
  }

render() {
  return (<>
      <Steps
        direction="vertical"
        current={this.state.current}
        onChange={this.changeSurveyStep}
        items={[
          {
            className: this.state.questions.q1.answer !== undefined ? "step-open-by-default" : null,
            title: this.props.gender !== "2" ? this.state.questions.q1.question : this.state.questions.q1.question_female,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q2.question : this.state.questions.q2.question_female,
            className: this.state.questions.q2.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q3.question : this.state.questions.q3.question_female,
            className: this.state.questions.q3.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q3"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q4.question : this.state.questions.q4.question_female,
            className: (this.state.questions.q4.answer !== undefined ||
              this.state.questions.q4a.answer !== undefined ||
              this.state.questions.q4b.answer !== undefined ||
              this.state.questions.q4c.answer !== undefined ||
              this.state.questions.q4d.answer !== undefined ||
              this.state.questions.q4e.answer !== undefined ||
              this.state.questions.q4f.answer !== undefined ||
              this.state.questions.q4g.answer !== undefined ||
              this.state.questions.q4i.answer !== undefined ||
              this.state.questions.q4j.answer !== undefined ||
              this.state.questions.q4k.answer !== undefined ||
              this.state.questions.q4l.answer !== undefined
            ) ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <div className="mi-3">
                <label>{this.props.gender !== "2" ? this.state.questions.q4a.question : this.state.questions.q4a.question_female}</label>
                <YesOrNoRadios skey={"q4a"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.props.gender !== "2" ? this.state.questions.q4b.question : this.state.questions.q4b.question_female}</label>
                <YesOrNoRadios skey={"q4b"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.props.gender !== "2" ?this.state.questions.q4c.question : this.state.questions.q4c.question_female}</label>
                <YesOrNoRadios skey={"q4c"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.props.gender !== "2" ? this.state.questions.q4d.question : this.state.questions.q4d.question_female}</label>
                <YesOrNoRadios skey={"q4d"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.props.gender !== "2" ? this.state.questions.q4e.question : this.state.questions.q4e.question_female}</label>
                <YesOrNoRadios skey={"q4e"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.props.gender !== "2" ? this.state.questions.q4f.question : this.state.questions.q4f.question_female}</label>
                <YesOrNoRadios skey={"q4f"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.props.gender !== "2" ? this.state.questions.q4g.question : this.state.questions.q4g.question_female}</label>
                <YesOrNoRadios skey={"q4g"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.props.gender !== "2" ? this.state.questions.q4i.question : this.state.questions.q4i.question_female}</label>
                <YesOrNoRadios skey={"q4i"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.props.gender !== "2" ? this.state.questions.q4j.question : this.state.questions.q4j.question_female}</label>
                <YesOrNoRadios skey={"q4j"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.props.gender !== "2" ? this.state.questions.q4k.question : this.state.questions.q4k.question_female}</label>
                <YesOrNoRadios skey={"q4k"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

                {this.state.q4k === true &&

                  <SurveyTextarea skey={"q4kz"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

                }

              </div>
              <div className="mi-3">
                <label>{this.props.gender !== "2" ? this.state.questions.q4l.question : this.state.questions.q4l.question_female}</label>
                <YesOrNoRadios skey={"q4l"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q5.question : this.state.questions.q5.question_female,
            className: this.state.questions.q5.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q5"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          this.props.gender !== "1" && {
            title: this.props.gender !== "2" ? this.state.questions.q6.question : this.state.questions.q6.question_female,
            className: this.state.questions.q6.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q6"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
        ]}
      />
    </>)
  }
}