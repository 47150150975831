import ReactComponent from "../app/ReactComponent";
import {Button, Col, Form, Input, Result, Row, Space, Spin, Layout, Image} from "antd";
import {Link} from "react-router-dom";
import React, {createRef} from "react";
import {LeftOutlined} from "@ant-design/icons";
import {isEmail} from "../helpers/utils";
import App from "../app/App";

export class LostPasswordPage extends ReactComponent {
  formRef = createRef()
  state = {
    loading_form: false,
    complete: false
  }

  componentDidMount() {

    this.pushPageAnalytics("Zapomenuté heslo - Moje Amber")

  }

  async handleLostPasswordForm(data) {

    void this.updateState({loading_form: true})

    try {

      if (data.email === undefined) throw App.$e('Vyplňte prosím e-mail.')
      if (!isEmail(data.email)) throw App.$e('Neplatný e-mail.')

      await this.$api({
        action: 'carecloud_api',
        route: 'send-password-setup-email',
        method: 'POST',
        data: {
          email: data.email
        }
      })

      //this.$toast.success(this.$t('notices.successfully_updated'))

      void this.updateState({
        complete: true
      })
      this.formRef.current.resetFields()

    } catch (error) {

      App.$e("E-mail nenalezen.")
      console.error(error)

    } finally {

      void this.updateState({loading_form: false})

    }

  }

  render() {
    return (
      <Layout tagName="main" className="lost-password">
        <Row align="center" justify="center" gutter={[20, 20]} className="margin-auto">
          <Col xl={24}>
            <Spin spinning={this.state.loading_form} size={"large"}>
              <Image preview={false} src={require('../image/moje-amber-key@2x.png')} style={{maxWidth: '21.6em', marginBottom: '3em'}}></Image>
              <div style={{maxWidth: 560}} className="card">
                <h1 className="card-title">Zapomenuté heslo</h1>
                <p className="card-text">Po zadání e-mailu Vám přijde e-mail <br/> s odkazem na obnovení hesla do portálu dárce.</p>

                {this.state.complete &&
                  <Result
                    style={{padding: 0}}
                    status="success"
                    title="Odesláno"
                    subTitle="Na e-mail vám byl zaslán odkaz na obnovení hesla."
                    extra={
                      <Link to="/">
                        <Button type="default" className="btn btn-primary">Zpět na přihlášení</Button>
                      </Link>
                    }/>
                }

                {!this.state.complete &&
                  <Form
                    ref={this.formRef}
                    layout="vertical"
                    onFinish={(...args) => this.handleLostPasswordForm(...args)}>
                      <Form.Item label="E-mailová adresa" name="email" rules={[{
                        required: true, message: "Vyplňte prosím e-mail."
                      }]}>
                        <Input placeholder="Zadejte váš e-mail" size="large"/>
                      </Form.Item>
                    <Space style={{marginTop: 20}} size={[18,20]} direction="vertical">
                      <Button
                        htmlType="submit" type="primary" className="btn btn-primary">Obnovit heslo</Button>
                      <Link to="/">
                        <Button type="text" className="btn-cancel" icon={<LeftOutlined style={{marginTop: '2px'}}/>}>Zpět na přihlášení</Button>
                      </Link>
                    </Space>
                  </Form>
                }
              </div>
            </Spin>
          </Col>
        </Row>
      </Layout>
    )
  }
}