import ReactComponent from "../app/ReactComponent";
import {Layout, Row, Col, Image, Space, Alert, Spin} from 'antd'

import {RewardsItem} from '../components/layout/RewardsItem'
import React from 'react'
import Query from "../app/Query";
import CoinSVG from "../image/coin.svg";
import {Link} from "react-router-dom";

export class RewardsPage extends ReactComponent {
  state = {
    loading_page: true,
    available_points: 0,
    rewards: [],
    overview: {
      items: [],
      sorter: {
        sort_field: 'points_cost',
        sort_direction: 'asc',
        sort_fields: [
          'points_cost',
          'name'
        ]
      },
      pagination: {
        page_length: 100,
        current_page: 1,
        total_items: 1,
        current_offset: 0
      }
    }
  }

  componentDidMount() {

    this.pushPageAnalytics("Odměny - Moje Amber")

    void this.getPageData()

  }

  getPageData = async () => {
    void this.updateState({
      loading_page: true
    })

    const points = await this.$app.$store('account').getPoints()

    void this.updateState({
      available_points: points
    })

    await this.getRewards()

    void this.updateState({
      loading_page: false
    })

  }

  async getRewards(offset = this.state.overview.pagination.current_offset, sort_field = this.state.overview.sorter.sort_field, sort_direction = this.state.overview.sorter.sort_direction) {
    const query = new Query('rewards')

    query.add('count', this.state.overview.pagination.page_length)
    query.add('offset', offset)
    query.add('sort_field', sort_field)
    query.add('sort_direction', sort_direction)
    query.add('is_valid', true)
    query.add('reward_group', 1)

    const {rewards = []} = await this.$api({
      action: 'carecloud_user_api',
      route: query.route
    })

    void this.updateState({
      rewards: rewards
    })

  }

  render() {
    return (
      <Layout tagName={"main"} className="male rewards">
        <Row align="center">
          <Col xs={24}>
            <h1>Amber konto</h1>
            <Row align="center">
              <Col xs={12} md={0}>
                <Space direction="horizontal" align="center" size={[27, 20]} style={{justifyContent: 'center'}} wrap>
                  <div className="blue-circle mobile">
                    <p>Stav konta</p>
                    <h2>{this.state.available_points} <img alt="coin" width={20} height={20} src={CoinSVG}/></h2>
                    <p>{this.$app.$tc('common', 'points', this.state.available_points)}</p>
                  </div>
                  <Link to={"/rewards-history"}>
                    <div className="order mobile">
                      <Image preview={false} src={require("../image/Amber-konto@2x.png")}></Image>
                      <p>Moje objednávky</p>
                    </div>
                  </Link>
                  <Link to={"/points-history"}>
                    <div className="order mobile">
                      <Image preview={false} width={80} src={require("../image/Moje-pripisy_V1@2x.webp")}></Image>
                      <p style={{marginTop: 0}}>Moje přípisy</p>
                    </div>
                  </Link>
                </Space>
              </Col>
            </Row>
            <h4 className="mb-1">Přehled odměn< /h4>
          </Col>
          <Col xs={24}>
            <p className="mb-3 margin-auto" style={{maxWidth: 500}}>Vážení dárci, rádi bychom vás informovali o současné nedostupnosti bezdrátových sluchátek Amber Plasma.</p>
          </Col>
          <Col xs={0} md={12} lg={12} xl={12} style={{paddingRight: 105, textAlign: 'right'}}>
            <Space direction="vertical" align="center" size={[0, 42]}>
              <div className="blue-circle">
                <p>Stav konta</p>
                <h2>{this.state.available_points}{/*<CountUp end={this.state.available_points} duration={2} separator={" "}/>*/} <img alt="coin" width={20} height={20} src={CoinSVG}/></h2>
                <p>{this.$app.$tc('common', 'points', this.state.available_points)}</p>
              </div>
              <Link to={"/rewards-history"}>
                <div className="order">
                  <Image preview={false} src={require("../image/Amber-konto@2x.png")}></Image>
                  <p>Moje objednávky</p>
                </div>
              </Link>
              <Link to={"/points-history"}>
                <div className="order">
                  <Image preview={false} width={80} src={require("../image/Moje-pripisy_V1@2x.webp")}></Image>
                  <p style={{marginTop: 0}}>Moje přípisy</p>
                </div>
              </Link>
            </Space>
          </Col>
          <Col xs={24} md={12} lg={12} xl={12}>
            <Spin spinning={this.state.loading_page} size="large" />
            <Space size={[24, 15]} className="rewards-item-container" wrap>
              {!this.state.loading_page && this.state.rewards.length === 0 &&
                <Alert
                  message="Žádné odměny k dispozici."
                  type="info"
                  banner={true}
                  showIcon
                />}
              {this.state.rewards.length !== 0 &&
                this.state.rewards.map(item => (
                  <RewardsItem key={item.reward_id} reward={item} available_points={this.state.available_points} reloadRewardsPageData={this.getPageData}/>
                ))
              }
            </Space>
          </Col>
        </Row>
      </Layout>
    )
  }
}