import ReactComponent from "../app/ReactComponent";
import {ReservationForm} from "../components/ReservationForm";

export class ReservationPage extends ReactComponent {

  async componentDidMount() {
    this.pushPageAnalytics("Rezervace odběru - Moje Amber")
  }

  render() {
    return (
      <>
        <ReservationForm data={this.state} $auth={this.props.$auth}/>
      </>
    )
  }
}