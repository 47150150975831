import ReactComponent from "../app/ReactComponent"
import Query from "../app/Query"
import {Col, Layout, List, Row, Space, Spin, Table, Typography, Tag} from "antd"
import {prettyDate} from "../helpers/utils"
import CoinSVG from "../image/coin.svg"
import React from "react"
import dayjs from "dayjs"
import {Link} from "react-router-dom"
import {LeftOutlined} from "@ant-design/icons"

class PointsHistoryPage extends ReactComponent {

  state = {
    loading_table: false,
    available_points: 0,
    points: [],
    overview: {
      table: {
        headers: [
          {
            title: "Datum přípisu/odpisu ABC",
            dataIndex: "created",
            key: "created"
          },
          {
            title: "Typ připsaných/odepsaných ABC",
            dataIndex: "name",
            key: "name"
          },
          {
            title: "Počet ABC",
            dataIndex: "points",
            key: "points"
          }
        ],
        rows: []
      },
      pagination: {
        defaultCurrent: 1,
        total: 0,
        defaultPageSize: 10,
        current: 1
      }
    }
  }

  componentDidMount() {
    this.pushPageAnalytics("Váš přípis a odpis kreditů - Moje Amber")

    void this.getPageData()
  }

  getPageData = async () => {
    const points = await this.$app.$store("account").getPoints()

    void this.updateState({
      available_points: points
    })

    await this.getPointsHistory()

  }

  getPointsHistory = async (offset = this.state.overview.pagination.current) => {
    void this.updateState({loading_table: true})

    try {
      const query = new Query("point-history")

      query.add("count", this.state.overview.pagination.defaultPageSize)
      query.add("offset", (offset - 1) * this.state.overview.pagination.defaultPageSize)
      query.add("sort_field", "point_history_record_time")
      query.add("sort_direction", "desc")

      const {point_history = [], total_items = 0} = await this.$api({
        action: "carecloud_api",
        route: query.route
      })

      const overviewRows = point_history.map((point, index) => {
        const {point_history_record_time, point_amount, point_operation_note, extended_data} = point
        const expirationDate = JSON.parse(extended_data).date_of_points_expiration

        return {
          key: index,
          created: this.getPointDate(point_history_record_time),
          name: (
            <Typography.Text className={point_amount > 0 && "bold"}>
              {point_operation_note}
            </Typography.Text>
          ),
          points: (
            <Tag className={`points ${point_amount < 0 && "points_write_off"}`}>
              {point_amount > 0 ? `+ ${point_amount}` : point_amount.toString().replace("-", "- ")}
              <img alt="coin" width={17} height={17} src={CoinSVG}/>
            </Tag>
          )
          //valid: this.getExpirationDate(expirationDate)
        }
      })

      await this.updateState({
        points: point_history, overview: {
          ...this.state.overview,
          table: {
            ...this.state.overview.table,
            rows: overviewRows
          },
          pagination: {
            ...this.state.overview.pagination,
            total: total_items,
            current: offset
          }
        }
      })

      return Promise.resolve()

    } catch (error) {

      console.log(error)

    } finally {

      void this.updateState({loading_table: false})

    }

  }

  getPointDate(date) {
    return prettyDate(date)
  }

  getExpirationDate(expirationDate) {
    const createdAtDate = dayjs()
    const validToDate = dayjs(expirationDate, "YYYY-MM-DD HH:mm:ss")

    if(!validToDate.isValid())
      return null

    const years = validToDate.diff(createdAtDate, "year")
    const remainingDate = validToDate.diff(createdAtDate.add(years, "year"), "day")

    const textBehindTheYear = years > 0 ? this.$app.$tc("rewards", "expiration_years", years) : ""
    const textBehindTheDay = remainingDate > 0 ? this.$app.$tc("rewards", "expiration_days", remainingDate) : ""

    return (years > 0 || remainingDate > 0) ? (
      <Tag className="active">
        Za {years > 0 && years} {textBehindTheYear} {remainingDate > 0 && remainingDate} {textBehindTheDay}
      </Tag>
    ) : (
      <Tag className="non-active">Expirováno</Tag>
    )
  }

  tablePaging = async (args) => {
    let current = typeof args === "number" ? args : args.current

    await this.updateState({loading_table: true})

    await this.getPointsHistory(current)

    this.scrollToTop()

    void this.updateState({loading_table: false})

  }

  render() {

    return (
      <Layout tagName="main">
        <Row align="center" className="points-history-overview">
          <Col xs={24} style={{maxWidth: 1200}}>
            <Space className="rewards-history-title" size={[0, 0]}>
              <Link to={"/rewards"}><LeftOutlined/><span className="back-to-rewards">Zpět na Amber konto</span></Link>
              <h1>Váš přípis a odpis kreditů</h1>
            </Space>
            <h3>Aktuální stav vašeho konta <Tag>{this.state.available_points}<img alt="coin" width={25} height={25} src={CoinSVG}/></Tag></h3>
          </Col>
          <Col xs={0} xl={24} style={{maxWidth: 1200}}>
            <Spin spinning={this.state.loading_table} size="large">
              <Table
                columns={this.state.overview.table.headers}
                dataSource={this.state.overview.table.rows}
                onChange={this.tablePaging}
                pagination={this.state.overview.pagination}
              />
            </Spin>
          </Col>
          <Col xs={23} sm={24} xl={0} style={{maxWidth: 500}}>
            <Spin spinning={this.state.loading_table} size={"large"}>
              <List header="Datum a čas / Počet ABC / Typ ABC"
                    pagination={{...this.state.overview.pagination, onChange: this.tablePaging}}
                    dataSource={this.state.overview.table.rows} renderItem={(item) => (
                <List.Item>
                  <Space direction="vertical" style={{width: "100%", textAlign: "left"}}>
                    <Space direction="horizontal" className="list-tag-btn-wrapper" wrap>
                      <Typography.Text style={{fontWeight: 500}}>{item.created}</Typography.Text>
                      <Typography.Text>{item.points}</Typography.Text>
                    </Space>
                    <Space direction="horizontal" className="list-tag-btn-wrapper" wrap>
                      <Typography.Text>{item.name}</Typography.Text>
                    </Space>
                  </Space>
                </List.Item>
              )}/>
            </Spin>
          </Col>
        </Row>
      </Layout>
    )
  }

}

export default PointsHistoryPage