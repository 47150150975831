import ReactComponent from "../../app/ReactComponent";
import {Button, Modal, Typography} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

export class GDPRModal extends ReactComponent{
  state = {
    open: false
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Button
          type="link"
          onClick={() => {void this.updateState({open: true})}}><InfoCircleOutlined /></Button>
        <Modal
          title="Souhlasím se zpracováním svých osobních údajů za účelem vedení evidence opakovaných dárců a jejich odběrů, odměn a bonusů a související komunikace s dárci"
          width={1000}
          className="gdpr-modal"
          open={this.state.open}
          onOk={() => {void this.updateState({open: false})}}
          onCancel={() => {void this.updateState({open: false})}}>

          <p>Vzhledem k tomu, že máme zájem na opakovaném dárcovství a chceme motivovat a odměňovat vícenásobné dárce, vedeme evidenci opakovaných dárců, jejich odběrů, odměn a bonusů a s těmito dárci v této souvislosti komunikujeme. Vaše osobní údaje budeme pro tento účel zpracovávat, pokud nám byl při příjmu v dárcovském centru nebo při registraci do Portálu dárce (případně později) z Vaší strany udělen souhlas se zpracováním Vašich osobních údajů pro tento účel. Právním základem pro toto zpracování osobních údajů je tedy Vámi udělený souhlas (jedná se o právní základ dle čl. 6 odst. 1 písm. a) Nařízení GDPR). Výše uvedený souhlas je samozřejmě zcela dobrovolný a jeho neudělení nebude pro Vás mít žádné negativní důsledky. Máte právo kdykoliv tento souhlas odvolat. Případným odvoláním tohoto souhlasu ale nebude dotčena zákonnost zpracování Vašich osobních údajů založená na tomto souhlasu před jeho odvoláním. Přístup k Vašim osobním údajům uvedeným ve výše zmíněné evidenci budou mít pouze naši pracovníci a případně naši zpracovatelé. Pro tento účel budeme Vaše osobní údaje zpracovávat do ukončení smluvního vztahu vzniklého mezi Vámi a Správcem nebo do odvolání Vašeho souhlasu.</p>
          <br/>
      </Modal>

      </>
    )
  }
}