export const PHONE_COUNTRY_CODES = [
  {
    country: 'at',
    code: 43
  },
  {
    country: 'be',
    code: 32
  },
  {
    country: 'bg',
    code: 359
  },
  {
    country: 'hr',
    code: 385
  },
  {
    country: 'cy',
    code: 357
  },
  {
    country: 'cz',
    code: 420
  },
  {
    country: 'dk',
    code: 45
  },
  {
    country: 'et',
    code: 372
  },
  {
    country: 'fi',
    code: 358
  },
  {
    country: 'de',
    code: 49
  },
  {
    country: 'gr',
    code: 30
  },
  {
    country: 'hu',
    code: 36
  },
  {
    country: 'ic',
    code: 354
  },
  {
    country: 'ir',
    code: 353
  },
  {
    country: 'it',
    code: 39
  },
  {
    country: 'lv',
    code: 371
  },
  {
    country: 'li',
    code: 423
  },
  {
    country: 'lt',
    code: 370
  },
  {
    country: 'lx',
    code: 352
  },
  {
    country: 'mt',
    code: 356
  },
  {
    country: 'nl',
    code: 31
  },
  {
    country: 'no',
    code: 47
  },
  {
    country: 'pl',
    code: 48
  },
  {
    country: 'pt',
    code: 351
  },
  {
    country: 'ro',
    code: 40
  },
  {
    country: 'sk',
    code: 421
  },
  {
    country: 'si',
    code: 386
  },
  {
    country: 'es',
    code: 34
  },
  {
    country: 'se',
    code: 46
  },
  {
    country: 'ua',
    code: 380
  }
]

export const AP_ANNONYMOUS_BOOKING_TICKETS = [
  {
    name: 'Odběr krevní plazmy - jste náš stávající dárce',
    ticket_id: '8fed961f4f9dfa4f7935089f8e'
  },
  {
    name: 'Vstupní prohlídka - mám zájem darovat',
    ticket_id: '8cd7319da35d4c4ae53a128a9c'
  }
]

export const AP_YEARLY_CHECKUP_BOOKING_TICKET_ID = '86d4817fec4fda5a4c431a493b'

export const AP_BOOKING_TICKET_REGULAR_BLOOD_DONATION_ID = '8fed961f4f9dfa4f7935089f8e'

export const AP_BOOKING_STATUS_ARRIVED_ID = '8fed961f4f9dfa4f7935089f8e'
export const AP_BOOKING_STATUS_ARRIVED_ON_TIME_ID = '84de1207b5f6ca59f34f9dd4f2'
export const AP_BOOKING_STATUS_ACTIVE_ID = '81d1d16eb51f6a911de06f0435'
export const AP_BOOKING_STATUS_RESOLVED_ID = '87e05b9782dba69c0912c608e7'
export const AP_BOOKING_STATUS_CANCELED_ID = '8cd7319da35d4c4ae53a128a9c'
export const AP_CUSTOMER_SOURCE_ID = '89d1a607a35811f18c787d9d4c'
export const AP_ANNONYMOUS_CUSTOMER_SOURCE_ID = '87d5d7a433962adc2e960be104'
export const AP_AGREEMEMT_TYPE_ID = '87e05b9782dba69c0912c608e7'
export const AP_ADDITIONAL_ADDRESS_TYPE_ID = '87e05b9782dba69c0912c608e7'
export const AP_TIMELINE_EVENT_TYPE_ID = '8cc89f128a48808e4eb9721a86'
export const AP_SURVEY_EVENT_TYPE_ID = '8ec2961ae013889d8eb9d888c4'
export const AP_REFERENCE_OTHER_OPTION_ID = '81dab577ce1fb6a3337366c5ed'

export const AP_SURVEY_ALLOWED_SEGMENTS = [
  '87d5d7a433962adc2e960be104',
  '85dcb8ea54c493953db37d1ba8'
]

export const AP_PRODUCT_RESERVATION_SOURCE_ID = {
  'darek_na_objednavku': '84de1207b5f6ca59f34f9dd4f2',
  'brandovy_produkt': '8fdb62b527c894d7de47fc554a',
  'voucher': '8ed8b2e9e836bfbd4e592630ac'
}

/**
 * 0 - Canceled
 * 1 - Entered
 * 2 - Accepted
 * 3 - Ready
 * 4 - Delivered
 * 5 - In progress
 * 6 - Not Picked up
 * 7 - Ordered
 * 8 - Being solved
 *
 */
export const API_RESERVATION_STATE = {
  0: 'Zrušená',
  1: 'Zadaná',
  2: 'Přijatá',
  3: 'Připravená',
  4: 'Doručená',
  5: 'Zpracovává se',
  6: 'Nevyzvednutá',
  7: 'Objednaná',
  8: 'V řešení'
}