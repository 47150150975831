import ReactComponent from '../app/ReactComponent'
import {Col, Layout, Row, Spin, Table, List, Typography, Space, Divider} from 'antd'
import Query from '../app/Query'
import {
  AP_BOOKING_STATUS_ACTIVE_ID,
  AP_BOOKING_STATUS_ARRIVED_ID
} from '../helpers/misc'
import {getBookingStatusNameByStatusId, mapBookings} from '../services/bookings'
import App from '../app/App'
import dayjs from 'dayjs'
import {prettyDate, sleep} from "../helpers/utils"
import {PROPERTY_BOOKINGS_STORE_KEY} from '../helpers/properties'
import {CancelReservationModal} from '../components/buttons/CancelReservationModal'
import {ReservationTag} from '../components/tag/ReservationTag'

export class ReservationOverviewPage extends ReactComponent {
  state = {
    loading_table: false,
    constants: {
      AP_BOOKING_STATUS_ARRIVED_ID,
      AP_BOOKING_STATUS_ACTIVE_ID
    },
    booking_stores: [],
    booking_statuses: [],
    overview: {
      table: {
        loading: true,
        headers: [
          {
            title: 'Datum a čas rezervace',
            dataIndex: 'date',
            key: 'date',
          },
          {
            title: 'Služba',
            dataIndex: 'service',
            key: 'service',
          },
          {
            title: 'Pobočka',
            dataIndex: 'store',
            key: 'store',
          },
          {
            title: 'Stav',
            dataIndex: 'status',
            key: 'status',
          },
          {
            title: 'Možnosti',
            dataIndex: 'action',
            key: 'action'
          }
        ],
        rows: []
      },
      pagination: {
        defaultCurrent: 1,
        total: 0,
        defaultPageSize: 10,
        current: 1
      }
    }
  }

  async componentDidMount () {

    this.pushPageAnalytics('Přehled rezervací - Moje Amber')

    void this.updateState({ loading_table: true })

    await this.getInitialData()

    await this.getDataForOverview()

    void this.updateState({ loading_table: false })

  }

  async getInitialData () {

    try {

      const { items: booking_stores = [] } = await this.$api({
        action: 'carecloud_api',
        route: `booking-ticket-properties/${PROPERTY_BOOKINGS_STORE_KEY}`
      })

      const { booking_statuses = [] } = await this.$app.$resource('booking-statuses', {
        action: 'carecloud_api',
        route: 'booking-statuses'
      })

      await this.updateState({
        booking_stores: booking_stores,
        booking_statuses: booking_statuses
      })

      return Promise.resolve()

    } catch (error) {

      console.log(error)

    } finally {

    }

  }

  async getDataForOverview (offset = this.state.overview.pagination.current) {

    void this.updateState({ loading_page: true })

    try {

      const query = new Query('bookings')

      query.add('count', this.state.overview.pagination.defaultPageSize)
      query.add('offset', (offset - 1) * this.state.overview.pagination.defaultPageSize)
      query.add('sort_field', 'created_at')
      query.add('sort_direction', 'desc')
      query.add('add_booking_items', 'full')

      const { bookings = [], total_items = 0 } = await this.$api({
        action: 'carecloud_api',
        route: query.route
      })

      let mapped_bookings = mapBookings(bookings).map((item) => {
        return {
          date: this.getBookingDate(item.valid_from),
          service: item.booking_ticket.name,
          store: this.getBookingStoreName(item.additional_properties).split(', ').slice(1).join(', '),
          status: <ReservationTag status={this.getBookingStatusName(item.booking_item_status)}/>,
          action: <CancelReservationModal item={item} onSubmit={(...args) => {
            this.cancelBooking(...args)
          }}/>
        }
      })

      this.setState((prevState) => ({
        ...prevState,
        overview: {
          ...prevState.overview,
          table: {
            ...prevState.overview.table,
            rows: mapped_bookings
          },
          pagination: {
            ...prevState.overview.pagination,
            total: total_items,
            current: offset
          }
        }
      }))

      return Promise.resolve()

    } catch (error) {

      console.log(error)

    } finally {

      void this.updateState({ loading_page: false })

    }

  }

  tablePaging = async (args) => {
    let current = typeof args === 'number' ? args : args.current

    await this.updateState({ loading_table: true })

    await this.getDataForOverview(current)

    this.scrollToTop()

    void this.updateState({ loading_table: false })

  }

  getBookingDate (date) {

    return prettyDate(date)

  }

  getBookingStoreName (additional_properties = []) {

    const { property_value: booking_stores = [] } = (additional_properties.find(({ property_id }) => property_id === PROPERTY_BOOKINGS_STORE_KEY) ?? {})
    const store_property_value = (booking_stores.length ? booking_stores.map(({ name }) => name) : [this.$app.$tc('common', 'unknown', 2)])

    return store_property_value.join(',')

  }

  getBookingStatusName (id) {

    return getBookingStatusNameByStatusId(this.state.booking_statuses, id, this.$app.$tc('common', 'unknown', 1))

  }

  isAllowedToCancel ({ booking_item_status, valid_from }) {

    if ((booking_item_status === this.state.constants.AP_BOOKING_STATUS_ARRIVED_ID)) return false

    if ((booking_item_status === this.state.constants.AP_BOOKING_STATUS_ACTIVE_ID)) return true

    return dayjs(valid_from).isSameOrAfter(dayjs())

  }

  async cancelBooking (item, cancellation_reason, cancellation_note) {

    void this.updateState({ loading_table: true })

    try {

      if (!this.isAllowedToCancel(item)) throw 'Rezervaci nelze zrušit.'

      const { booking_id, customer_id } = item

      const putData = JSON.stringify({
        cancellation_reason: cancellation_reason,
        cancellation_note: cancellation_note
      })

      await this.$api({
        action: 'carecloud_api',
        route: `bookings/${booking_id}`,
        method: 'PUT',
        data: {
          booking: {
            customer_id: customer_id,
            data: putData
          }
        }
      })

      await sleep(1000)

      await this.$api({
        action: 'carecloud_api',
        route: `bookings/${booking_id}`,
        method: 'DELETE'
      })

      await this.getDataForOverview()

      App.$toast('Rezervace zrušena.')

    } catch (error) {

      console.log(error)

    } finally {

      void this.updateState({ loading_table: false })

    }

  }

  render () {
    return (
      <Layout tagName="main">
        <Row align="center" className="reservation-overview">
          <Col xs={24} style={{ maxWidth: 1400 }}>
            <h1>Přehled rezervací</h1>
          </Col>
          <Col xs={0} xl={24} style={{ maxWidth: 1400 }}>
            <Spin spinning={this.state.loading_table} size={'large'}>
              <Table
                columns={this.state.overview.table.headers}
                dataSource={this.state.overview.table.rows}
                onChange={this.tablePaging}
                pagination={{
                  defaultCurrent: this.state.overview.pagination.defaultCurrent,
                  total: this.state.overview.pagination.total,
                  defaultPageSize: this.state.overview.pagination.defaultPageSize,
                  current: this.state.overview.pagination.current
                }}
              />
            </Spin>
          </Col>
          <Col xs={23} sm={24} xl={0} style={{ maxWidth: 500 }}>
            <Spin spinning={this.state.loading_table} size={'large'}>
              <List header="Služba / Datum a čas / Pobočka / Stav"
                    pagination={{ ...this.state.overview.pagination, onChange: this.tablePaging }}
                    dataSource={this.state.overview.table.rows} renderItem={(item) => (
                <List.Item>
                  <Space direction="vertical" style={{ width: '100%', textAlign: 'left' }}>
                    <Typography.Text style={{ fontWeight: 500 }}>{item.service}</Typography.Text>
                    <Space direction="horizontal" wrap>
                      <Typography.Text>{item.date}</Typography.Text>
                      <Divider type="vertical"/>
                      <Typography.Text>{item.store}</Typography.Text>
                    </Space>
                    <Space direction="horizontal" className="list-tag-btn-wrapper" wrap>
                      <Typography.Text>{item.status}</Typography.Text>
                      <Typography.Text>{item.action}</Typography.Text>
                    </Space>
                  </Space>
                </List.Item>
              )}/>
            </Spin>
          </Col>
        </Row>
      </Layout>
    )
  }
}