import ReactComponent from '../../app/ReactComponent'
import { Button, Form, Image, Modal, Popconfirm, Radio, Input, Space } from 'antd'
import dayjs from 'dayjs'
import { AP_BOOKING_STATUS_ACTIVE_ID, AP_BOOKING_STATUS_ARRIVED_ID } from '../../helpers/misc'
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons'
import React from 'react'
import App from '../../app/App'

export class CancelReservationModal extends ReactComponent {
  state = {
    complete: false,
    open: false,
    cancelation_reason: null,
    cancelation_note: '',
    constants: {
      AP_BOOKING_STATUS_ARRIVED_ID,
      AP_BOOKING_STATUS_ACTIVE_ID
    }
  }

  constructor (props) {
    super(props)
  }

  isAllowedToCancel ({ booking_item_status, valid_from }) {

    if ((booking_item_status === this.state.constants.AP_BOOKING_STATUS_ARRIVED_ID)) return false

    if ((booking_item_status === this.state.constants.AP_BOOKING_STATUS_ACTIVE_ID)) return true

    return dayjs(valid_from).isSameOrAfter(dayjs())

  }

  async handleCancelReservationFormSubmit (data) {

    void this.updateState({ complete: true })

    try {

      if (this.state.cancelation_reason === null) throw 'Vyberte prosím jednu možnost.'
      if (this.state.cancelation_reason === 5 && this.state.cancelation_note.length === 0) throw 'Napište prosím svůj důvod.'

      this.props.onSubmit(this.props.item, this.state.cancelation_reason, this.state.cancelation_note)

      void this.updateState({ open: false })

    } catch (error) {

      App.$toast(error)

    } finally {

      void this.updateState({ complete: false })

    }
  }

  changeReason = (data) => {
    void this.updateState({ cancelation_reason: data })
  }

  changeReasonNote = (data) => {
    void this.updateState({ cancelation_note: data })
  }

  render () {
    return (
      <>
        <Button
          type="link"
          danger
          disabled={!this.isAllowedToCancel(this.props.item)}
          onClick={() => {void this.updateState({ open: !this.state.open })}}
          icon={<DeleteOutlined/>}
        >Zrušit</Button>
        <Modal
          title="Důvody pro zrušení rezervace"
          width={600}
          className="cancel-reservation-modal"
          open={this.state.open}
          onCancel={() => {void this.updateState({ open: false })}}
          footer={null}>
          <Form
            layout="vertical"
            onFinish={(...args) => this.handleCancelReservationFormSubmit(...args)}
          >
            <Radio.Group onChange={(data) => this.changeReason(data.target.value)}>
              <Space direction="vertical" style={{ width: '100%' }} size={[18, 10]}>
                <Radio value={1}>Zdravotní důvody</Radio>
                <Radio value={2}>Časové důvody</Radio>
                <Radio value={3}>Pobyt v zahraničí</Radio>
                <Radio value={4}>Očkování nebo tetování</Radio>
                <Radio value={6}>Předobjednání</Radio>
                <Radio value={5}>Jiné</Radio>

                {this.state.cancelation_reason === 5 &&
                  <div className="textarea-container">
                    <Input.TextArea rows={3}
                                    onChange={(data) => this.changeReasonNote(data.target.value)}
                                    placeholder="Vložit důvod"/>
                  </div>}

              </Space>
            </Radio.Group>
            <Space size={[18, 10]} className="btn-container">
              <Button type="text" className="btn-cancel" icon={<CloseOutlined/>}
                      onClick={() => {void this.updateState({ open: false })}}>Zpět</Button>
              <Button htmlType="submit" type="primary" className={`btn btn-primary`}>Potvrdit zrušení</Button>
            </Space>
          </Form>
        </Modal>
      </>
    )
  }
}