import dayjs from 'dayjs'

const RESOURCE_CACHE_NAME = 'ckp.cache'
const RESOURCE_CACHE_VALIDITY = 60 // minutes

export class Cache {

	static get resources () {

		return (window.localStorage.getItem(RESOURCE_CACHE_NAME) ? JSON.parse(window.localStorage.getItem(RESOURCE_CACHE_NAME)) : {})

	}

	static extend (data) {

		window.localStorage.setItem(RESOURCE_CACHE_NAME, JSON.stringify({
			...Cache.resources,
			...data
		}))

	}

	static clear () {

		window.localStorage.removeItem(RESOURCE_CACHE_NAME)

	}

}

export class Resource {

	constructor (resource, customer_id) {

		this.data = resource
		this.customer_id = customer_id

	}

	get statestamp () {

		return (this.data.hasOwnProperty('statestamp') ? this.data.statestamp : false)

	}

	get validity () {

		return (this.statestamp ? dayjs().diff(dayjs.unix(this.statestamp), 'minute') : false)

	}

	get owner () {

		return (this.data.hasOwnProperty('customer_id') ? this.data.customer_id : false)

	}

	get isValid () {

		return (Number.isInteger(this.validity) && this.validity < RESOURCE_CACHE_VALIDITY && this.owner && this.owner === this.customer_id)

	}

}
