import React from 'react'
import App from "./App"
import {pushAnalytics} from "../helpers/global"
import {redirect} from "react-router-dom";

export default class ReactComponent extends React.Component {
  title = "Moje Amber"

  constructor(props) {
    super(props);
  }

  get $app() {
    return App.instance
  }

  get $api() {
    return this.$app.$api
  }

  scrollToTop () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  handleError(error) {

    if(typeof error === 'string')
      return this.$app.$e(error)

    // ERRORS FROM BRIDGE
    if(typeof error === 'object' && error.hasOwnProperty('errors')) {
      error.errors.forEach((item) => {
        this.$app.$e(`${item.title || ""}`)
      })
      return
    }

    if(typeof error === 'object') {

      const { error_data = {}, exception } = error
      const { invalid_params = [] } = error_data

      const messages = invalid_params.map(({name, reason, specific_parameter_name = null}) => {
        if(specific_parameter_name !== null)
          return this.$app.$t('errors', `${name}_${reason ? reason : 'default'}${specific_parameter_name ? `_${specific_parameter_name}` : ''}`)
        else
          return this.$app.$t('errors', `${name}_${reason ? reason : 'default'}`)
      })

      if(messages.length)
        return this.$app.$e(messages.join('\n'))

      // Customer is logged out => Redirect to login
      if(exception === "customer_not_logged_in_exception") {
        redirect("/")
        return null
      }

      if(exception)
        return this.$app.$e(this.$app.$t('errors', exception))

      return this.$app.$e(this.$app.$t('errors', 'generic'))

    }

    return this.$app.$e(this.$app.$t('errors', 'generic'))
  }

  async updateState(modifiedData) {
    return new Promise((resolve, reject) => {

      this.setState(prevState => ({
        ...prevState,
        ...modifiedData
      }), () => {
        resolve()
      })

    })
  }

  setTitle (title) {
    document.title = this.title = title
  }

  pushPageAnalytics (title = null) {

    if(title !== null)
      this.setTitle(title)

    const ga_event_data = {
      event: "page_view",
      platform: "web",
      "environment": "production",
      "language": 'cs',
      page: {
        title: this.title,
        url: document.location.href,
        path: document.location.pathname
      }
    }

    pushAnalytics(ga_event_data)

    pushAnalytics(ga_event_data, "cxDataLayer")

  }
}