import Singleton from "./Singleton"
import {Cache, Resource} from "./Cache"
import dayjs from "dayjs";
import {message} from "antd";
import axios from "axios";
import {SiI18Next} from "react-icons/si";
import {AccountResource} from "../resources/AccountResource";
import {CommonResource} from "../resources/CommonResource";

let localesJSON = require('../locales_cz.json')

export default class App extends Singleton {
  static API_URL = (document.location.hostname === "localhost") ? "http://localhost/api/" : `https://${document.location.hostname}/api/`
  touched_flag = false
  static STORE = {
    is_authorized: false,
    customer_id: null,
    is_test_user: null
  }
  i18nDATA = {}

  static getCustomerID () {
    return App.STORE.customer_id || null
  }

  constructor() {
    super()

    this.message = message
    this.i18nDATA = localesJSON

    axios.interceptors.response.use((response) => {

      return response.data

    }, (error) => {

      if(error.hasOwnProperty('response') && error.response.hasOwnProperty('error'))
        return Promise.reject(error.response.data)
      else if(error.hasOwnProperty('message'))
        return Promise.reject(error.message)
      else
        return Promise.reject("No internet connection")

    })

    window.$app = this
  }

  async $api(request) {
    return new Promise(async (resolve, reject) => {

      try {
        const { action = 'default', headers = null } = request

        delete request.headers

        const response = await axios({
          withCredentials: true,
          url: `${App.API_URL}?action=${action}`,
          method: 'POST',
          headers: headers,
          data: {
            ...request
          }
        })

        /*if(response && response.hasOwnProperty('errors')){

          response.errors.forEach((message) => {
            App.$e(message.title)
          })
          reject(response.error)

        }
        if(response && response.hasOwnProperty('error')) {

          App.$e(response.error.title)
          reject(response.error)

        }*/

        if(response && response.hasOwnProperty('error')) reject(response.error)
        else resolve(response)

      } catch (error) {

        reject(error)

      }
    })
  }

  async $resource(name, request) {
    const resources = Cache.resources
    const customer_id = App.STORE.customer_id
    const resource = (resources.hasOwnProperty(name) ? new Resource(resources[name], customer_id) : false)

    if(resource instanceof Resource && resource.isValid) return Promise.resolve(resource.data)

    const response = await this.$api(request)

    if(response && typeof response === 'object') {

      const data = {}
      const statestamp = dayjs().unix()

      data[name] = {
        ...response,
        statestamp,
        customer_id
      }

      Cache.extend(data)

    }

    return Promise.resolve(response)
  }

  /**
   * Get translations
   */
  $t(...keys) {
    return this.findByKeys(keys, localesJSON)
  }

  $tc(...args) {

    const count = args.pop()
    const plurals = this.findByKeys(args, localesJSON).split("|").map(item => item.trim())

    if(count === 1)
      return plurals[0]
    if(plurals.length === 3 && (4 < count || count === 0))
      return plurals[2]

    return plurals[1]
  }

  findByKeys(keys, data) {

    // Result key found
    if(keys.length === 0)
      return data

    if (data[keys[0]] !== undefined ) {
      let original = keys.shift()
      return this.findByKeys(keys, data[original])
    } else {
      console.error(`JS lang key ${keys} not exist`)
      return `[JS lang key ${keys} not exist]`
    }
  }

  static $toast (content, type = "success") {
    void message.open({type: type, content: content})
  }
  static $e(content) {
    this.$toast(content, "error")
  }

  $e(content) {
    App.$e(content)
  }

  /**
   *
   * @returns {Promise<{is_test_user: null, is_authorized: boolean, customer_id: null}>}
   */
  async $auth () {
    if(!this.touched_flag) {
      let data = await this.$api({action: "touch"})
      App.STORE = data
      this.touched_flag = true
    }

    return App.STORE
  }

  $store (storeName) {
    if(storeName === 'account')
      return (new AccountResource())

    else if(storeName === "common")
      return (new CommonResource())

    else {

      App.$e("Store not found")
      return null

    }
  }
}