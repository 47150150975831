import {LoginPage} from "./page/LoginPage"
import './sass/styles.sass';
import ReactComponent from "./app/ReactComponent";
import {BrowserRouter, redirect, Route, Routes} from "react-router-dom";
import {Header} from "./layout/Header";
import {Footer} from "./layout/Footer";
import {DashboardPage} from "./page/DashboardPage";
import {ReservationPage} from "./page/ReservationPage";
import {RewardsPage} from "./page/RewardsPage";
import {HealthPage} from "./page/HealthPage";
import {ProfilePage} from "./page/ProfilePage";
import {ReservationConfirm} from "./page/ReservationConfirm";
import {LostPasswordPage} from "./page/LostPasswordPage";
import {RegistrationPage} from "./page/RegistrationPage";
import {ReservationOverviewPage} from './page/ReservationOverviewPage';
import {SurveyPage} from "./page/SurveyPage";
import {RewardsHistoryPage} from "./page/RewardsHistoryPage";
import PointsHistoryPage from "./page/PointsHistoryPage"
import {Spin} from "antd"
import {QuestionnairePage} from "./page/QuestionnairePage"

export default class Root extends ReactComponent {

  constructor(props) {
    super(props)

    this.state = {
      is_authorized: false,
      customer_id: null,
      is_test_user: null,
      loading: true,
      overlayVisibility: true
    }

  }

  async componentDidMount() {

    try {
      let response = await this.$app.$auth()

      this.setState(prevState => ({
        ...response,
        loading: false
      }))

      setTimeout(() => {
        void this.updateState({overlayVisibility: false})
      }, 500)

    } catch (error) {
      console.log(error)

      await this.updateState({loading: false})
    }

  }

  get $auth() {
    return {
      is_authorized: this.state.is_authorized,

      getCustomerID: () => {

        return this.state.customer_id

      },

      authenticate: async (data) => {

        await this.$api({
          action: "login",
          method: "POST",
          data: data
        }).then(async (res) => {
          if (res.hasOwnProperty('was_successfull') && res.was_successfull === false) {
            this.handleError(res)
            //this.inputPassword.current.value = ""
            //this.inputEmail.current.focus()
          }
          // TODO: IMPORTANT, response is null add something
          await this.resolveLogin(res)

        }).catch(e => {

          this.handleError(e)

        })
      },

      authenticateTestUser: () => {

        this.$api({
          action: "login-test-user",
        }).then((res) => this.resolveLogin(res))

      },

      logout: () => {

        this.$api({action: "logout"}).then(res => {
          if (res && res.hasOwnProperty('was_successfull') === true) {
            void this.resolveLogin()
            redirect('/')
          }
        })

      }
    }
  }

  async authUser(data) {
    await this.$api({
      action: "login",
      method: "POST",
      data: data
    }).then(async (res) => {
      //if (res.was_successfull !== true) {
        //this.inputPassword.current.value = ""
        //this.inputEmail.current.focus()
      //}
      // TODO: IMPORTANT, response is null add something
      await this.resolveLogin(res)


    }).catch(e => {
      console.log(e)
    })

  }

  authTestUser() {
    this.$api({
      action: "login-test-user",
    }).then((res) => this.resolveLogin(res))
  }

  async resolveLogin(response = null) {
    const customer = await this.$api({
      action: "touch"
    })

    this.$app.STORE = customer
    this.setState(prevState => ({...customer}))
  }

  render() {
    return (
      <BrowserRouter>
        <div className={`App ${this.state.dev === true ? "dev_mode" : ""}`}>
          <Header $auth={this.$auth}/>

          {this.state.overlayVisibility && (
            <div className={`loading-overlay-layer ${this.state.loading || 'fade-out'}`}>

            </div>
            )}

          {!this.state.loading && (
            <>
              {!this.state.is_authorized ? (
                <Routes>
                <Route path="/" element={<LoginPage  $auth={this.$auth}/>}></Route>
                <Route path="/lost-password" element={<LostPasswordPage/>}></Route>
                <Route path="/registration" element={<RegistrationPage $auth={this.$auth}/>}></Route>
                </Routes>

              ) : (

                <Routes>
                  <Route path="/" element={<DashboardPage auth={this.state.auth}/>}></Route>
                  <Route path="/reservation" element={<ReservationPage $auth={this.$auth}/>} ></Route>
                  <Route path="/reservation-confirm" element={<ReservationConfirm/>}></Route>
                  <Route path="/rewards" element={<RewardsPage/>}></Route>
                  <Route path="/rewards-history" element={<RewardsHistoryPage/>}></Route>
                  <Route path="/points-history" element={<PointsHistoryPage/>}></Route>
                  <Route path="/health" element={<HealthPage/>}></Route>
                  <Route path="/profile" element={<ProfilePage/>}></Route>
                  <Route path="/reservations" element={<ReservationOverviewPage/>}></Route>
                  <Route path="/survey" element={<SurveyPage/>}></Route>
                  <Route path="/survey-registration" element={<QuestionnairePage type="registration" />}></Route>
                  <Route path="/survey-pruvodka" element={<QuestionnairePage type="guide" />}></Route>
                  <Route path="/survey-annual" element={<QuestionnairePage type="annual" />}></Route>
                </Routes>

              )}
              <Footer/>
            </>)}
        </div>
      </BrowserRouter>
    )
  }
}