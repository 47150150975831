import ReactComponent from '../../app/ReactComponent'
import {Image, Modal, Button, Form, Select, Typography, Tooltip, Spin, Result} from 'antd'
import {CaretDownOutlined} from '@ant-design/icons'
import React from 'react'
import CoinSVG from "../../image/amber-coin.svg"
import {PROPERTY_REWARDS_MESSAGE_FOR_CUSTOMER_KEY, PROPERTY_REWARDS_NOTE_KEY} from "../../helpers/properties";
import App from "../../app/App";
import {AP_PRODUCT_RESERVATION_SOURCE_ID} from "../../helpers/misc";
import {Link} from "react-router-dom";

const closeIcon = <Image preview={false} src={require('../../image/Close@2x.png')}/>

export class RewardsItem extends ReactComponent {
  state = {
    loading_form: false,
    open: false,
    complete: false
  }

  constructor(props) {

    super(props);

  }

  async useReward(data) {

    void this.updateState({loading_form: true})

    try {
      const {
        reward_id
      } = this.props.reward

      const {store = ""} = data

      const {currencies = []} = await this.$app.$resource('currencies', {
        action: 'carecloud_api',
        route: 'currencies'
      })
      const currency_id = currencies[0].currency_id


      const products_tied_to_reward = await this.$api({
        action: "carecloud_api",
        route: `rewards/${reward_id}/products`
      })

      const product_id = (Array.isArray(products_tied_to_reward.products) && products_tied_to_reward.products.length > 0) ? products_tied_to_reward.products[0].product_id : 0
      let product_price = 0

      // Set price from product
      if(Array.isArray(products_tied_to_reward.products) && products_tied_to_reward.products.length > 0) {
        if(products_tied_to_reward.products[0].hasOwnProperty('prices'))
            product_price = products_tied_to_reward.products[0].prices[0].value
          else
            product_price = products_tied_to_reward.products[0].price
      }


      const request = {
        action: 'carecloud_api',
        method: 'POST',
        route: `rewards/${reward_id}/actions/create-voucher`
      }


      // CREATE VOUCHER
      const {voucher_id} = await this.$api(request)


      // GET DATA FOR RESERVATION AND MAKE PRODUCT RESERVATION
      const {code} = await this.$api({
        action: "carecloud_api",
        route: `vouchers/${voucher_id}`
      })

      const product_reservation_source_id = (this.props.reward.tags.length > 0 && AP_PRODUCT_RESERVATION_SOURCE_ID.hasOwnProperty(this.props.reward.tags[0].text_id)) ?
        AP_PRODUCT_RESERVATION_SOURCE_ID[this.props.reward.tags[0].text_id] : product_reservation_source_id


      const {product_reservation_id} = await this.$api({
        action: "carecloud_api",
        method: "POST",
        route: "product-reservations",
        data: {
          product_reservation: {
            store_id: store,
            customer_id: "{customer_id}",
            benefit_codes: [code],
            product_reservation_source_id: product_reservation_source_id,
            product_reservation_items: [
              {
                reservable_product_id: product_id,
                amount: 1,
                unit_price: product_price,
                vat_rate: 21
              }
            ],
            total_price: product_price,
            currency_id: currency_id
          }
        },

      })

      void this.updateState({complete: true})
      this.props.reloadRewardsPageData()

    } catch (error) {

      App.$e(error)

    } finally {

      void this.updateState({loading_form: false})

    }
  }

  requiresNote() {

    const {property_value: requires_note = false} = (this.properties.find(({property_id}) => property_id === PROPERTY_REWARDS_NOTE_KEY) ?? {})

    return Boolean(requires_note)

  }

  getMessageForCustomer() {

    const {property_value: message = false} = (this.properties.find(({property_id}) => property_id === PROPERTY_REWARDS_MESSAGE_FOR_CUSTOMER_KEY) ?? {})

    return message

  }


  render() {
    return (
      <>
        <Tooltip title={("ontouchstart" in document.documentElement) ? "" : this.props.reward.name}>
          <Button onClick={() => {
            void this.updateState({open: true})
          }} className={`rewards-item ${this.props.available_points < this.props.reward.points_cost ? 'disabled' : ''}`}>
            {this.props.reward.image_urls.length > 0 &&
              <Image preview={false} src={this.props.reward.image_urls[0]} style={{maxHeight: 51, maxWidth: 51, width: "auto"}}/>
            }
            <p><span>{this.props.reward.points_cost}</span> <span><img alt="coin" width={16} height={16} src={CoinSVG}/></span>
            </p>
          </Button>
        </Tooltip>

        <Modal
          title={this.props.reward.name}
          width={375}
          open={this.state.open}
          onCancel={() => {
            void this.updateState({
              open: false,
              complete: false
            })
          }}
          footer={[]}
          closeIcon={closeIcon}
          className="rewards-item-modal"
        >
          <Spin spinning={this.state.loading_form} size="large">

            {this.state.complete ?

              <Result
                status="success"
                title="Dárek objednán."
                subTitle="Váš dárek bude doručen na vybrané odběrové centrum. Dárek na centrum bude doručen do 14 dní od potvrzení objednávky."
                extra={
                  <Link to="/rewards-history">
                    <Button type="default" className="btn btn-primary" size="large">Moje objednávky</Button>
                  </Link>
                }/>

              :

              <>

                <p>{this.props.reward.subtitle}</p>

                {this.props.reward.image_urls.length > 0 &&
                  <Image preview={false} src={this.props.reward.image_urls[0]} style={{maxHeight: 172, maxWidth: 256, width: "auto"}}/>
                }

                <Form
                  layout="vertical"
                  onFinish={(...args) => this.useReward(...args)}
                >
                  <Form.Item
                    label="Vyberte odběrové centrum pro vyzvednutí"
                    name="store"
                    rules={[{
                      required: true,
                      message: "Prosím vyberte pobočku."
                    }]}
                  >
                    <Select
                      style={{height: "48px"}}
                      placeholder="Prosím vyberte"
                      suffixIcon={<CaretDownOutlined/>}
                      size="large"
                      className="ant-select-suffix"
                      options={this.props.reward.store_list.map(item => ({
                        label: item.name,
                        value: item.store_id
                      }))}
                    />

                  </Form.Item>
                  <Button htmlType="submit" type="primary" className="btn btn-primary">Objednat</Button>
                  <p><Typography.Text type="secondary" className="text-info">Váš dárek bude doručen na vybrané odběrové centrum. Dárek na centrum bude doručen do 14 dní od potvrzení objednávky.</Typography.Text></p>
                </Form>

              </>

            }

          </Spin>
        </Modal>
      </>
    )
  }
}