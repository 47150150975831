export function getStoreNameByStoreId (stores = [], id, default_value = '') {

	const { name: store_name = default_value } = (stores.find(({store_id}) => store_id === id) ?? {})

	return store_name

}

export function getPartnerIdByStoreId (stores = [], id, default_value = 0) {

	const { partner_id = default_value } = (stores.find(({store_id}) => store_id === id) ?? {})

	return partner_id

}

export function getStoreIdBySystemId (stores = [], id, default_value = 0) {

	const { store_id = default_value } = (stores.find(({system_id}) => system_id === id) ?? {})

	return store_id

}

export function getStoreNameBySystemId (stores = [], id, default_value = '') {

	const { name: store_name = default_value } = (stores.find(({system_id}) => system_id === id) ?? {})

	return store_name

}
