import ReactComponent from "../app/ReactComponent";
import {Layout, Row, Col, Button, Space, Typography, Image} from 'antd'

const { Text } = Typography

export class ReservationConfirm extends ReactComponent {
  render() {
    return (
      <Layout tagName="main">
        <Row align="center" className="reservation-confirm">
          <Col xs={24} md={16}>
            <Space direction="vertical" size={[0,15]}>
              <Image preview={false} src={require('../image/woman-2@2x.png')}></Image>
              <Text className="reservation-confirm-span">Vaše <Text className="highlighting">rezervace je potvrzena.</Text><br/>Informace o termímu Vám zasíláme jako SMS.</Text>
              <Text>Budeme se na Vás těšit</Text>
              <Button type="button" href="/" className="btn btn-secondary">Zavřít</Button>
            </Space>
          </Col>
        </Row>

      </Layout>
    )
  }
}